@if (!!partyCollection) {
<div class="p-3 pt-2">
	<div class="d-flex justify-content-end mb-1">
		@if (isAllCollapsed) {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.expandAll()" data-cy="templatePartiesExpandAll">
			Expand All
		</button>
		} @else {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.collapseAll()" data-cy="templatePartiesCollapseAll">
			Collapse All
		</button>
		}
	</div>
	<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false">
		@for (group of partyCollection.groups; track group.groupID) {
		<div ngbAccordionItem="{{ group.groupID }}" [collapsed]="groupedParties[group.groupID]?.length ? false : true"
			#item="ngbAccordionItem">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded(group.groupID.toString())}" ngbAccordionButton
					[attr.data-cy]="'templatePartiesToggle' + group.groupLabel | trimWhitespace">
					<span class="cursor-pointer">
						{{ group.groupLabel }}
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded(group.groupID.toString()) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded(group.groupID.toString()) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded(group.groupID.toString())">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						<div class="p-3">
							<div class="panel__ops d-flex justify-content-end pb-1">
								<button class="btn btn-icon btn-success" title="Add Party to Section"
									(click)="editTemplateParty($event, group.groupID, group.groupLabel)"
									data-pendo="AddPartyToSection">
									<i class="far fa-plus"></i>
								</button>
							</div>
							<table class="table rl-table table-hover" cdkDropList
								(cdkDropListDropped)="drop($event, group.groupID)">
								<thead>
									<tr>
										<th class="ps-3">ID</th>
										<th>Party Name</th>
										<th>Source Party</th>
										<th>Primary</th>
										<th>Multiple</th>
										<th>Required</th>
										<th>Editable</th>
										<th>Deletable</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr class="no-items-message">
										<td colspan="10" class="border-0">
											<div class="d-flex align-items-center justify-content-center pt-3">
												<i class="far fa-th-list"></i>
												<h5 class="mb-0 ms-3">No parties found.</h5>
											</div>
										</td>
									</tr>
									@for (party of groupedParties[group.groupID]; track party.partyID) {
									<tr cdkDrag cdkDragLockAxis="y">
										<td>
											<span class="pt-1 drag__handle position-relative" cdkDragHandle
												data-cy="dragDropRow">
												<i class="far fa-ellipsis-v"></i>
											</span>
											{{party.partyID}}
										</td>
										<td>
											<a [routerLink]="[]"
												(click)="editTemplateParty($event, group.groupID, group.groupLabel, party)"
												data-cy="editTemplateParty">{{party.partyName}}</a>
										</td>
										<td>
											{{party.sourceParty}}
										</td>
										<td>
											{{yesOrNo(party.primaryIndicator)}}
										</td>
										<td>
											{{yesOrNo(party.multipleIndicator)}}
										</td>
										<td>
											{{yesOrNo(party.requiredIndicator)}}
										</td>
										<td>
											{{yesOrNo(party.editIndicator)}}
										</td>
										<td>
											{{yesOrNo(party.deleteIndicator)}}
										</td>
										<td class="p-1">
											<div class="hover-icons text-end">
												<button class="btn btn-icon btn-remove btn-sm me-1"
													(click)="deleteParty(party, deletePartyPrompt)" title="Delete"
													data-cy="deleteParty">
													<i class="far fa-trash-alt fa-sm"></i>
												</button>
											</div>
										</td>
									</tr>
									}
								</tbody>
							</table>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
		}
	</div>
</div>
} @else {
<div class="p-4 text-center">
	<rl-loader></rl-loader>
</div>
}

<ng-template #deletePartyPrompt let-prompt="prompt">
	<div>
		<p>
			@if (targetNumFound) {
			There are {{ targetNumFound }} user{{targetNumFound === 1 ? '' : 's'}} associated to one
			or more templates for the {{ partyName }} Party from the {{ charTypeId |
			charTypeName }} template: {{ templateName }}.<br><br>
			<strong>You must remove these associations before this party can be deleted.</strong>
			} @else {
			You are about to delete the {{ partyName }} Party from the {{ charTypeId |
			charTypeName }} template: {{ templateName }}.<br><br>
			<strong>Are you sure you want to proceed?</strong>
			}
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="deletePartyCancel">
			{{ targetNumFound ? 'OK' : 'Cancel'}}
		</button>
		<button *ngIf="!targetNumFound" class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
			data-cy="deletePartyConfirm">
			Delete Party
		</button>
	</div>
</ng-template>