<div class="modal-header">
	<h2 class="modal-title">
		Edit Sections
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-4">
			<div cdkDropList class="drop-list-container" (cdkDropListDropped)="dropSection($event)">
				<div class="d-flex align-items-center p-1" *ngFor="let section of sections; let i = index" cdkDrag>
					<span class="drag__handle pe-2" cdkDragHandle>
						<i class="far fa-ellipsis-v"></i>
					</span>
					<div class="flex-grow-1">
						<rl-text-input class="mb-4" [(ngModel)]="section.title" [dataLabel]="'SectionTitleInput'">
						</rl-text-input>
					</div>
					<span class="ps-2">
						<button class="btn btn-icon btn-info" (click)="moveToTop(i)" title="Move to top"
							data-cy="MoveSection">
							<i class="far fa-arrow-to-top"></i>
						</button>
					</span>
					<span class="ps-2">
						<button class="btn btn-icon btn-remove p-2" title="Remove"
							(click)="deleteSection(deleteGroupPrompt, i)" data-cy="RemoveSection">
							<i class="far fa-times"></i>
						</button>
					</span>
				</div>
				<div class="d-flex align-items-center p-1">
					<div class="flex-grow-1">
						<rl-text-input class="mb-4" [(ngModel)]="newGroupName" [placeholder]="'Add New Section'"
							(keydown.enter)="addSection()" [dataLabel]="'NewSectionInput'">
						</rl-text-input>
					</div>
					<span class="ps-2">
						<button class="btn btn-icon btn-success" (click)="addSection()" title="Add New"
							[disabled]="newGroupName?.length === 0" data-cy="AddSection">
							<i class="far fa-plus"></i>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	@if (form?.errors?.["titleUniqueness"]) {
	<div class="text-danger">
		Titles must be unique.
	</div>
	}
	@if (form?.errors?.["sectionsRequired"]) {
	<div class="text-danger">
		You must have at least one section.
	</div>
	}
	<button type="button" class="btn btn-light ms-4" (click)="close()" data-cy="CancelButton">Cancel</button>
	<button (click)="done()" [disabled]="!isValid" class="btn btn-success" data-cy="ApplyButton">
		Apply
	</button>
</div>

<ng-template #deleteGroupPrompt let-prompt="prompt">
	<div>
		<div class="alert alert-warning">
			This panel group has one or more child sections.
		</div>
		<p>
			Are you sure you want to delete <b>{{ prompt.context.group.title }}</b>?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="NoButton">
			No
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="YesButton">
			Yes
		</button>
	</div>
</ng-template>