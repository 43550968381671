<div class="p-3 pt-2">
	<div class="d-flex justify-content-end mb-1">
		@if (isAllCollapsed) {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.expandAll()" data-cy="templateAssociationsExpandAll">
			Expand All
		</button>
		} @else {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.collapseAll()" data-cy="templateAssociationsCollapseAll">
			Collapse All
		</button>
		}
	</div>
	<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="false">
		@for (panel of panels; track panel.panelID) {
		<div ngbAccordionItem="{{ panel.panelID }}" [collapsed]="false" #item="ngbAccordionItem">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded(panel.panelID.toString())}" ngbAccordionButton
					[attr.data-cy]="'templateAssociationsToggle' + panel.panelName | trimWhitespace">
					<span class="cursor-pointer">
						{{ panel.panelName }}
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded(panel.panelID.toString()) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded(panel.panelID.toString()) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded(panel.panelID.toString())">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						@if (!isLoaded) {
						<div class="p-4 text-center">
							<rl-loader></rl-loader>
						</div>
						} @else {
						@if (panel.panelID == 0) {
						<rl-template-associations-panel [charTypeId]="charTypeId" [templateId]="templateId"
							[templateName]="templateName"
							[templateAssociations]="templateAssociations"></rl-template-associations-panel>
						} @else {
						<rl-entities-associations-panel [charTypeId]="charTypeId" [templateId]="templateId"
							[isModule]="isModule" [allEntityAssociations]="allEntityAssociations"
							[panel]="panel"></rl-entities-associations-panel>
						}
						}
					</ng-template>
				</div>
			</div>
		</div>
		}
	</div>
</div>