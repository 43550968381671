<ng-container *ngIf="associationCharTypes.length; else noTemplateAssociations">
	<section class="d-flex justify-space-evenly flex-wrap entities-list">
		<div *ngFor="let group of associationCharTypes" class="flex-fill entities-list__col">
			<div class="entities-list__head">{{ group.charTypeID | charTypeName }}</div>
			<div class="entities-list__head_req" *ngIf="showHeader">
				<span class="d-inline-block form-check form-switch">
					<rl-checkbox-input *ngIf="shouldShowRequiredCheckbox(group.charTypeID)" [id]="group.charTypeID"
						[row]="group.charTypeID" [label]="requiredAssociation"
						(ngModelChange)="toggleRequired(group.charTypeID)"
						[ngModel]="isSelected(group.charTypeID)"></rl-checkbox-input>
				</span>
			</div>
			<div *ngFor="let in of rowCounter(numRows); let i = index" class="entities-list__item">
				<span *ngIf="i < group.charTypeTemplates.length" class="d-inline-block form-check form-switch">
					<rl-checkbox-input [id]="group.charTypeTemplates[i]?.childTemplateLabel"
						[row]="group.charTypeTemplates[i]" [label]="group.charTypeTemplates[i]?.childTemplateLabel"
						[ngModel]="group.charTypeTemplates[i].selected"
						(ngModelChange)="toggleSelected(group.charTypeTemplates[i])"
						[dataLabel]="'checkboxInput_' + group.charTypeTemplates[i]?.childTemplateLabel | trimWhitespace"></rl-checkbox-input>
				</span>
			</div>
		</div>
	</section>
</ng-container>

<ng-template #noTemplateAssociations>
	<div class="p-4 text-center">
		<span>No associated templates found.</span>
	</div>
</ng-template>