<div class="m-2">
	<div class="panel__ops d-flex justify-content-end pb-1">
		<button class="btn btn-icon btn-success" title="Add Fields to Section" (click)="addFields()">
			<i class="far fa-plus"></i>
		</button>
	</div>
	<table class="table rl-table table-hover" cdkDropList (cdkDropListDropped)="drop($event)">
		<thead>
			<tr>
				<th class="ps-3">ID</th>
				<th class="text-nowrap">Field Name</th>
				<th class="text-nowrap">Tag Label</th>
				<th class="text-nowrap">Data Type</th>
				<th>Visibility</th>
				<th>Required</th>
				<th>Multiple</th>
				<th class="text-nowrap">Conditional Field</th>
				<th>Include on Copy</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr class="no-items-message">
				<td colspan="10" class="border-0">
					<div class="d-flex align-items-center justify-content-center pt-3">
						<i class="far fa-th-list"></i>
						<h5 class="mb-0 ms-3">No items found.</h5>
					</div>
				</td>
			</tr>
			@for (char of groupChars; track char.characteristicID) {
			<tr cdkDrag cdkDragLockAxis="y" [cdkDragData]="char">
				<td class="text-nowrap">
					<span class="pt-1 drag__handle position-relative" cdkDragHandle data-cy="DragDropRow">
						<i class="far fa-ellipsis-v"></i>
					</span>
					{{char.characteristicID}}
				</td>
				<td>
					<a [routerLink]="[]" data-cy="editTemplateField" (click)="editField(char)">
						{{char.label}}
					</a>
				</td>
				<td>
					{{char.tagLabel}}
				</td>
				<td>
					{{char.dataTypeID | charDataTypeName}}
				</td>
				<td>
					{{char.visibilityIndicator | visibilityIndicatorName}}
				</td>
				<td>
					{{char.requiredIndicator | requiredIndicatorName}}
				</td>
				<td>
					{{char.multipleIndicator | multipleIndicatorName}}
				</td>
				<td>
					{{ char.triggerCharID ? "Yes" : "No" }}
				</td>
				<td>
					{{includeOnCopy(char.copyIndicator)}}
				</td>
				<td class="p-1">
					<div class="hover-icons text-end">
						<button class="btn btn-icon btn-remove btn-sm me-1" (click)="removeField(char)" title="Delete"
							data-cy="deleteField">
							<i class="far fa-trash-alt"></i>
						</button>
					</div>
				</td>
			</tr>
			}
		</tbody>
	</table>
</div>