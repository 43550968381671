<ng-container *ngIf="templateAssociations.length; else noTemplateAssociations">
	<table class="table rl-table table-hover">
		<thead>
			<tr>
				<th>Child of {{ templateName }}</th>
				<th *ngIf="charTypeId === 1 && templateAssociations[0].childCharTypeID === 1">
					Sync & Avails Settings
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let tempAssoc of templateAssociations; index as i">
				<td class="d-flex align-items-center">
					<span class="d-inline-block form-check form-switch">
						<rl-checkbox-input [id]="tempAssoc.childTemplateLabel" [row]="tempAssoc"
							[label]="tempAssoc.childTemplateLabel" [(ngModel)]="tempAssoc.selected"
							(ngModelChange)="toggleSelected(tempAssoc, i)"
							[dataLabel]="'templateAssociationsCheckbox' + i"></rl-checkbox-input>
					</span>
				</td>
				<td *ngIf="charTypeId === 1 && templateAssociations[0].childCharTypeID === 1" class="pt-2">
					<rl-dropdown-single [(ngModel)]="relRecType[i]" (onValueChange)="updateSyncAndAvails(tempAssoc, i)"
						[options]="syncAndAvailsOptionsList" [showEmptyOption]="true"
						[dataLabel]="'selectDropdownChildOfAssociation' + tempAssoc.childTemplateLabel"
						[id]="'sync-avails-' + i" [disabled]="!tempAssoc.selected" [isSmall]="true">
					</rl-dropdown-single>
				</td>
			</tr>
		</tbody>
	</table>
</ng-container>

<ng-template #noTemplateAssociations>
	<div class="p-4 text-center">
		<span>No associated templates found.</span>
	</div>
</ng-template>