import { transition, trigger, useAnimation } from "@angular/animations";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { EntityConfigUtil } from "config/utils/entity-config.util";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownMultipleComponent } from "rl-common/components/dropdown/dropdown-multiple/dropdown-multiple.component";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { AmortizationModel } from "rl-common/components/financial/financial.models";
import { FormTableControlDirective } from "rl-common/components/form-table/form-table-control.directive";
import { FormTableMessageDirective } from "rl-common/components/form-table/form-table-message.directive";
import { FormTableRowComponent } from "rl-common/components/form-table/form-table-row/form-table-row.component";
import { CharTypeId, VisibilityIndicator } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICharacteristicTemplateGroup } from "rl-common/models/i-characteristic-template-group";
import { IWfProcesses } from "rl-common/services/company/company.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { DealScopedOptionsUtil } from "rl-common/utils/deal-scoped-options.util";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ColorPickerInputComponent } from "../../../../../common/components/color-picker-input/color-picker-input.component";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { NumberInputComponent } from "../../../../../common/components/number-input/number-input.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";

interface TemplateForm {
	templateId?: FormControl<number>;
	charTypeId: FormControl<number>;
	sequence?: FormControl<number>;
	templateName: FormControl<string>;
	systemIndicator: FormControl<number>;
	templateGroup: FormControl<ICharacteristicTemplateGroup>;
	workflowProcess: FormControl<IWfProcesses>;
	calcSequence: FormControl<number>;
	visualization: FormControl<string>;
	dealScoped: FormControl<KeyValueOption>;
	selectedAmortModels: FormControl<AmortizationModel[]>;
}

interface KeyValueOption {
	key: number;
	value: string;
}
@Component({
	selector: "rl-template-edit",
	templateUrl: "./template-edit.component.html",
	styleUrls: ["./template-edit.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, TextInputComponent, DropdownSingleComponent, DropdownMultipleComponent, NumberInputComponent, ColorPickerInputComponent, FormTableRowComponent, FormTableControlDirective, FormTableMessageDirective]
})
export class TemplateEditComponent implements OnInit, OnDestroy {

	@Input()
	_charTypeId: number;

	@Input()
	_templateId: number;

	@Input()
	_charTypeName: string;

	@Input()
	templates: ICharacteristicTemplate[]

	@Output()
	onComplete = new EventEmitter<boolean>();

	_subs: Subscription[] = [];

	title: string;
	formLoaded = false;

	groups: DropdownOptions<ICharacteristicTemplateGroup> = {
		items: [],
		rowKeyFn: (type: ICharacteristicTemplateGroup) => type.characteristicTemplateGroupID,
		rowLabelFn: (type: ICharacteristicTemplateGroup) => type.characteristicTemplateGroupName
	};
	processes: DropdownOptions<IWfProcesses> = {
		items: [],
		rowKeyFn: (type: IWfProcesses) => type.processID,
		rowLabelFn: (type: IWfProcesses) => type.processName
	};
	dealScopedOptionsList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};
	selectedTemplate: ICharacteristicTemplate;
	form: FormGroup<TemplateForm>;
	isSaving = false;

	isSuperAdmin: boolean;

	amortOptions: DropdownOptions<AmortizationModel> = {
		rowLabelFn: (row) => row.name,
		rowKeyFn: (row) => row.id,
		items: [],
	};

	get isDeal() {
		return this._charTypeId === CharTypeId.Transaction;
	}

	straightLine = true;

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _sessionService: SessionService,
		private readonly _modalService: ModalServiceAbstract) { }

	async ngOnInit(): Promise<void> {
		this.isSuperAdmin = await this._sessionService.isRlAdmin$.pipe(take(1)).toPromise();

		if (this._templateId === null) {
			// create mode
			this.title = "Create New " + this._charTypeName + " Template";
			await this.setupCreateTemplate();
		} else {
			this.title = "Edit " + this._charTypeName + " Template";
			await this.setupEditTemplate();
		}

	}

	async setupDropdowns() {
		if (this._charTypeId === CharTypeId.Right) {
			this.getDealScopedOptionsList();
		}

		if (this._charTypeId === CharTypeId.Transaction) {
			this.amortOptions.items = await this._entityConfigService.amortizationModels(this._charTypeId).toPromise();
		}

		this.groups.items = await this._entityConfigService.getTemplateGroupList(this._charTypeId).toPromise();
		this.processes.items = await this._entityConfigService.getWorkflowProcesses(this._charTypeId).toPromise();
	}

	getTemplateGroupById(templateGroupID = null): ICharacteristicTemplateGroup {
		return {
			characteristicTemplateGroupID: templateGroupID,
			characteristicTemplateGroupName: null,
			sequenceNumber: null
		};
	}

	getWorkflowProcessById(processID = null): IWfProcesses {
		return {
			processID: processID,
			charTypeID: null,
			processName: null,
			processDescription: null
		};
	}

	async setupEditTemplate() {
		await this.setupDropdowns();
		const { template, workflowProcessId, selectedAmortModels } = await this._entityConfigService.getTemplate(this._charTypeId, this._templateId).toPromise();
		const selectedModels = this.amortOptions.items.filter(x => selectedAmortModels.includes(x.id));
		this.selectedTemplate = template;
		this.form = this._fb.group<TemplateForm>({
			templateId: this._fb.control({ value: template.templateID, disabled: true }),
			charTypeId: this._fb.control({ value: this._charTypeId, disabled: true }),
			sequence: this._fb.control({ value: template.sequenceNumber, disabled: true }),
			templateName: this._fb.control(template.templateName, [Validators.required, this.duplicateTemplateNameValidator]),
			systemIndicator: this._fb.control({ value: template.systemIndicator, disabled: !this.isSuperAdmin }),
			templateGroup: this._fb.control(this.getTemplateGroupById(template.templateGroupID)),
			workflowProcess: this._fb.control(this.getWorkflowProcessById(workflowProcessId), {}),
			dealScoped: this._fb.control({ key: template.dealScoped, value: null }),
			visualization: this._fb.control(template.visualization),
			calcSequence: this._fb.control(template.calcSequence),
			selectedAmortModels: this._fb.control(selectedModels)
		});
		this.title = "Edit " + this._charTypeName + " Template - <strong>" + template.templateName + "</strong>";

		this.formLoaded = true;
	}

	async setupCreateTemplate() {
		await this.setupDropdowns();
		this.form = this._fb.group<TemplateForm>({
			charTypeId: this._fb.control({ value: this._charTypeId, disabled: true }, Validators.required),
			templateName: this._fb.control("", [Validators.required, this.duplicateTemplateNameValidator]),
			systemIndicator: this._fb.control({ value: 0, disabled: !this.isSuperAdmin }),
			templateGroup: this._fb.control(this.getTemplateGroupById(this.groups.items[0]?.characteristicTemplateGroupID), Validators.required),
			workflowProcess: this._fb.control(this.getWorkflowProcessById(this.processes.items[0]?.processID), {}),
			dealScoped: this._fb.control({ key: 0, value: null }),
			visualization: this._fb.control(""),
			calcSequence: this._fb.control(0),
			selectedAmortModels: this._fb.control([])
		});

		this.formLoaded = true;
	}

	private getDealScopedOptionsList() {
		for (const d of EntityConfigUtil.getDealScopedOptions()) {
			if (d > -1) {
				this.dealScopedOptionsList.items.push({ key: d, value: DealScopedOptionsUtil.toDisplayName(d) });
			}
		}
	}

	duplicateTemplateNameValidator = (fc: FormControl<string>) => {
		if (!fc.value) {
			return null;
		}

		const isCreate = this._templateId === null;
		const cleanedTemplateName = fc.value.trim().toLowerCase();
		const isDuplicate = this.templates.some(t => t.templateName.trim().toLowerCase() === cleanedTemplateName && (isCreate || t.templateID !== this._templateId));

		return isDuplicate ? { "templateNameDuplicate": true } : null;
	};

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			const selectedAmortModels = this.form.controls.selectedAmortModels.value.map(x => x.id);
			let jobId: string = null;
			try {
				if (this._templateId != null) {
					const template: Partial<ICharacteristicTemplate> = {
						templateName: this.form.controls.templateName.value,
						templateGroupID: this.form.controls.templateGroup.value.characteristicTemplateGroupID,
						systemIndicator: this.form.controls.systemIndicator.value,
						dealScoped: this.form.controls.dealScoped?.value.key,
						visualization: this.form.controls.visualization?.value,
						calcSequence: this.form.controls.calcSequence?.value,
						sequenceNumber: this.form.controls.sequence?.value,
						visibilityIndicator: this.selectedTemplate?.visibilityIndicator ?? VisibilityIndicator.InternalExternal
					};
					const workflowProcessId = this.form.controls.workflowProcess.value.processID;
					jobId = await this._entityConfigService.updateTemplate(this._charTypeId, this._templateId, template as ICharacteristicTemplate, workflowProcessId, selectedAmortModels).toPromise();
				} else {
					const template: Partial<ICharacteristicTemplate> = {
						templateName: this.form.controls.templateName.value,
						templateGroupID: this.form.controls.templateGroup.value.characteristicTemplateGroupID,
						systemIndicator: this.form.controls.systemIndicator.value,
						dealScoped: this.form.controls.dealScoped?.value.key,
						visualization: this.form.controls.visualization?.value,
						calcSequence: this.form.controls.calcSequence?.value,
						visibilityIndicator: VisibilityIndicator.InternalExternal
					};

					const workflowProcessId = this.form.controls.workflowProcess.value.processID;
					jobId = await this._entityConfigService.createTemplate(this._charTypeId, template as ICharacteristicTemplate, workflowProcessId, selectedAmortModels).toPromise();
				}

				if (jobId) {
					await this._modalService.jobProgress(jobId, false, false, true).toPromise();
				}

			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				if (success) {
					this.onComplete.next(true);
					this._growlerService.success().growl("Your changes were saved.");
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	close() {
		this.onComplete.next(null);
	}

}
