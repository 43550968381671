<div class="modal-header">
	<h2 class="modal-title">
		@if(isNewPanel) {
		New Panel
		} @else {
		Edit Panel
		}
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-form-table-row [class.has-error]="form?.errors?.['titleUniqueness'] && !isValid">
		<ng-template rlFormTableLabel>Panel Title</ng-template>
		<ng-template rlFormTableControl>
			<rl-text-input class="mb-4" [formControl]="form.controls.title"
				[dataLabel]="'PanelTitleInput'"></rl-text-input>
		</ng-template>
		<ng-template rlFormTableMessage *ngIf="form?.errors?.['titleUniqueness'] && !isValid">
			Panel Title must be unique.
		</ng-template>
	</rl-form-table-row>
	<rl-form-table-row>
		<ng-template rlFormTableLabel>Panel Type</ng-template>
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.panelType" [options]="panelTypeOptions"
				[showEmptyOption]="false" [dataLabel]="'panelType'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	<rl-form-table-row>
		<ng-template rlFormTableLabel>Display On Summary</ng-template>
		<ng-template rlFormTableControl>
			<rl-checkbox-input [label]="''" [formControl]="form.controls.displayOnSummary"
				[dataLabel]="'DisplayOnSummary'"></rl-checkbox-input>
		</ng-template>
	</rl-form-table-row>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light ms-auto" (click)="close()" data-cy="CancelButton">Cancel</button>
	<button (click)="done()" [disabled]="!isValid" class="btn btn-success" data-cy="ApplyButton">
		Apply
	</button>
</div>