import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgClass, NgIf } from "@angular/common";
import { AfterViewChecked, Component, Injector, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { ConfigModalService } from "config/services/config-modal.service";
import { every } from "lodash";
import { IPartyAssociation, IPartyCollection, IPartyGroup } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { Subscription, of } from "rxjs";
import { concatMap, debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { NewTabInModalDirective } from "../../../../common/directives/new-tab-in-modal.directive";
import { CharTypeNamePipe } from "../../../../common/pipes/char-type-name.pipe";
import { TrimWhitespacePipe } from "../../../../common/pipes/trim-whitespace.pipe";
import { TemplateLandingService } from "../templates-landing/templates-landing.service";

@Component({
	selector: "rl-template-parties-content",
	templateUrl: "./template-parties-content.component.html",
	styleUrls: ["./template-parties-content.component.scss"],
	imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, CdkDropList, CdkDrag, CdkDragHandle, NewTabInModalDirective, RouterLink, LoaderComponent, NgIf, CharTypeNamePipe, TrimWhitespacePipe]
})
export class TemplatePartiesContentComponent implements OnInit, OnDestroy, AfterViewChecked {
	charTypeId: number;
	templateId: number;
	templateName: string;
	targetNumFound: number;
	partyName: string;

	partyCollection: IPartyCollection;
	groupedParties: _.Dictionary<IPartyAssociation[]> = {};

	sortField = "sequenceNumber";
	sortDir: "asc" | "desc" = "asc";

	_subs: Subscription[] = [];

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	@ViewChildren("item")
	accordionItemsQueryList: QueryList<NgbAccordionItem>;

	accordionItems: NgbAccordionItem[] = [];

	get isAllCollapsed() {
		return every(this.accordionItems, item => item.collapsed);
	}

	constructor(
		private readonly _entityConfigService: EntityConfigService,
		private readonly _templateService: TemplateLandingService,
		private readonly _injector: Injector,
		private readonly _modalService: ConfigModalService,
		private readonly _promptService: PromptService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _tokenStorageService: TokenStorageService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit() {
		this.init();
	}

	ngAfterViewChecked(): void {
		// accordionItems was not populating using the regular ole ngAfterViewInit
		this.accordionItems = this.accordionItemsQueryList.toArray();
	}

	init() {
		const sub = this._templateService.charTypeTemplateIds$.pipe(
			tap(([charTypeId, templateId]) => {
				this.charTypeId = charTypeId;
				this.templateId = templateId;
			}),
			switchMap(_ => this._entityConfigService.getTemplateParties(this.charTypeId, this.templateId)),
			tap(partyCollection => {
				partyCollection.groups = this.addNoPartyGroup(partyCollection.groups);
				this.partyCollection = partyCollection;
				this.buildPartyGroups();
			})
		).subscribe();

		const sub2 = this._templateService.templateMetaData$.pipe(
			map(cmd => this.templateName = cmd.template.templateName)
		).subscribe();

		this._subs.push(sub, sub2);
	}

	filterPartiesByGroup(chars: IPartyAssociation[], partyGroupID: number) {
		return chars.filter(x => x.partyGroupID === partyGroupID).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}

	private buildPartyGroups() {
		this.groupedParties = this.partyCollection.parties.reduce((acc, party) => {
			const targetParties: IPartyAssociation[] = this.partyCollection.parties.filter(p => p.partyGroupID === party.partyGroupID);
			acc[party.partyGroupID] = targetParties;
			return acc;
		}, {});
	}

	drop(event: CdkDragDrop<string[]>, partyGroupID: number) {
		if (event.previousIndex === event.currentIndex) {
			return;
		}
		moveItemInArray(this.groupedParties[partyGroupID], event.previousIndex, event.currentIndex);
		const orderedPartyIds = Object.values(this.groupedParties[partyGroupID]).map(gp => gp.partyID);
		const sub = this._entityConfigService.updatePartyGroupSequenceOrder(this.charTypeId, this.templateId, partyGroupID, orderedPartyIds).pipe(
			debounceTime(200)
		).subscribe();

		this._subs.push(sub);
	}

	editTemplateParty(e: Event, groupID, groupName, party = null) {
		const sub = this._modalService.editTemplateParty(this.partyCollection, groupID, groupName, party?.partyID, this._injector).pipe(
			filter(event => !!event),
			switchMap(event => of(event)),
			tap(x => {
				this._subs.forEach(s => s.unsubscribe());
				this.init();
			})
		).subscribe();
		this._subs.push(sub);
	}

	yesOrNo(v: number) {
		switch (v) {
			case 0:
				return "No";
			case 1:
				return "Yes";
		}
	}

	addNoPartyGroup(groups: IPartyGroup[]): IPartyGroup[] {
		const noPartyGroup: IPartyGroup = {
			divisionID: 0,
			groupID: 0,
			groupLabel: "[No Party Group]",
			groupDescription: "[No Party Group]",
			sequenceNumber: 0,
			expandIndicator: 0,
			expandGroupIndicator: 1,
			systemIndicator: 0,
			visibilityIndicator: 2,
			userID: 0
		};
		groups.push(noPartyGroup);
		return groups;
	}

	deleteParty(party: IPartyAssociation, template: TemplateRef<unknown>) {
		this.partyName = party.partyName;
		const sub = this._entityConfigService.getTemplatePartyUsersCount(this.charTypeId, this.templateId, party.partyID).pipe(
			switchMap(result => {
				this.targetNumFound = result;
				const title = this.targetNumFound ? `Unable to delete Party: ${this.partyName}` : `Delete Party: ${this.partyName}`;
				return this._promptService.confirm(title, template).pipe(
					filter(confirmDelete => confirmDelete),
					tap(() => this._growlerService.info().growl("Deleting..."))
				);
			}),
			switchMap(() => this._entityConfigService.deleteTemplatePartyAssoc(this.charTypeId, this.templateId, party.partyID)),
			concatMap(() => this._oneConfigService.refresh(this._tokenStorageService.jwt)),
			switchMap(() => this._entityConfigService.getTemplateParties(this.charTypeId, this.templateId)),
			tap(metaData => {
				this.partyCollection = metaData;
			})
		).subscribe(() => {
			this.buildPartyGroups();
			this._growlerService.success().growl("Party deleted successfully");
		});

		this._subs.push(sub);
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}
