<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeWorkflowModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">

				<rl-form-table-row [labelText]="'Type'" [required]="true"
					[class.has-error]="form.hasError('charTypeIdRequired') && form.controls.charType.touched">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.charType" [options]="charTypeList"
							[showEmptyOption]="true" [isReadOnly]="!isCreate" [dataLabel]="'selectDropdownType'">
						</rl-dropdown-single>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.hasError('charTypeIdRequired') && form.controls.charType.touched">
						Type is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Workflow Name'" [required]="true"
					[class.has-error]="form.hasError('processNameRequired') && form.controls.processName.touched">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.processName"
							[dataLabel]="'textInputWorkflowName'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.hasError('processNameRequired') && form.controls.processName.touched">
						Workflow Name is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Description'">
					<ng-template rlFormTableControl>
						<rl-text-area [formControl]="form.controls.processDescription" [styles]="{maxLength: 10000}"
							[dataLabel]="'textAreaDescription'"></rl-text-area>
					</ng-template>
				</rl-form-table-row>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelWorkflowEdit">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveWorkflowEdit">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>