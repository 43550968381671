<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Status Name'" [required]="true"
					[class.has-error]="form.controls.statusName.errors && form.controls.statusName.touched">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.statusName" [dataLabel]="'textInputStatusName'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage *ngIf="form.controls.statusName.errors && form.controls.statusName.touched">
						Status Name is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Description'">
					<ng-template rlFormTableControl>
						<rl-text-area [formControl]="form.controls.statusDescription" [styles]="{maxLength: 10000}"
							[dataLabel]="'textareaInputDescription'"></rl-text-area>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="isUnifiedWorkflowActive" [labelText]="'Revision Status'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.revisionStatus" [options]="revisionStatuses" [showEmptyOption]="false"
							[dataLabel]="'selectDropdownAssociatedRoles'" class="d-block me-2">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Show Indicators'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Active'" [formControl]="form.controls.activeIndicator"
							[dataLabel]="'checkboxInputShowIndicatorsActive'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>
				<rl-form-table-row [labelText]="''">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Delete'" [formControl]="form.controls.deleteIndicator"
							[dataLabel]="'checkboxInputShowIndicatorsDelete'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>
				<rl-form-table-row *ngIf="!isUnifiedWorkflowActive" [labelText]="''">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Lock'" [formControl]="form.controls.lockIndicator"
							[dataLabel]="'checkboxInputShowIndicatorsLock'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'System Indicator'">
					<ng-template rlFormTableControl>
						<rl-number-input [formControl]="form.controls.systemIndicator" [min]="-1" [max]="2"
							[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Valid Actions'">
					<ng-template rlFormTableCustomControl>
						<div class="char-data__control container m-0 ms-3 p-0 pt-1 scroll-vert border">
							<div class="char-data-element-display border-bottom">
								<rl-checkbox-input [label]="'Select All'" [ngModel]="allValidActionsSelected"
									(ngModelChange)="selectAllValidActions($event)" [dataLabel]="'checkboxInputValidActionsSelectAll'"
									class="d-block py-1"></rl-checkbox-input>
							</div>
							<div class="char-data-element-display" *ngFor="let action of actions">
								<label class="py-0" [attr.for]="action.actionID">
									<input type="checkbox" class="form-check-input me-2" [id]="action.actionID" [value]="action.actionID"
										[checked]="isActionIdChecked(action.actionID)"
										(change)="onValidActionChange(action.actionID, $event.target.checked)"
										[attr.data-cy]="'checkboxInputValidActions' + action.stepName | trimWhitespace" />
									{{ action.actionName }}
								</label>
							</div>
						</div>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Associated Roles'">
					<ng-template rlFormTableCustomControl>
						<div class="d-flex align-items-center bg-light p-2 ms-3 border-bottom"
							*ngFor="let assocRolesControl of associatedRolesFormControls; let i = index; let last = last" [class.mb-3]="last">
							<div class="d-flex flex-fill me-4">
								<!-- TODO: Implement <rl-dropdown-single> -->
								<select class="form-select me-2" [(ngModel)]="assocRolesControl.value.id" data-cy="selectDropdownAssociatedRoles">
									<option value="" selected>Select Role</option>
									<option *ngFor="let role of roles" [ngValue]="role.roleID + ' ' + role.roleName"
										[hidden]="omitRoleOption(role.roleID + ' ' + role.roleName)">{{
										role.roleID
										}} - {{ role.roleName }}</option>
								</select>
								<rl-text-input [ngModel]="assocRolesControl.value.label" [placeholder]="'Enter Label'"
									[dataLabel]="'textInputAssociatedRolesLabel'"></rl-text-input>
							</div>
							<button type="button" class="btn btn-icon btn-remove me-2 ms-auto" (click)="removeRow(i)" title="Remove Row"
								data-cy="removeRow"><i class="far fa-times"></i></button>
						</div>
						<div class="d-flex justify-content-between ms-3">
							<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="associatedRolesIsEmpty"
								data-cy="addAnotherRow">
								<i class="far fa-plus"></i> Add another row
							</button>
							<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="!associatedRolesIsEmpty"
								data-cy="addRow">
								<i class="far fa-plus"></i> Add row
							</button>
							<button type="button" class="btn btn-sm btn-remove text-grey" (click)="clearRows()" [hidden]="associatedRolesIsEmpty"
								data-cy="clearRow">
								<i class="far fa-times me-1"></i> Clear all
							</button>
						</div>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
				<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)" data-cy="saveButton">Save</button>
			</div>
		</div>
	</div>

	<ng-template #isLoading>
		<div class="modal-body" [@fadeIn]>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="shiny__loading__bg h-100 w-100">
						&nbsp;
					</div>
					<div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="shiny__loading__bg h-100 w-100">
						&nbsp;
					</div>
					<div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="shiny__loading__bg h-100 w-100">
						&nbsp;
					</div>
					<div>
						<div class="char-data__control container m-0">
							<div class="shiny__loading__bg w-100">
								&nbsp;
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>