import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { cloneDeep, first } from "lodash";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { FormTableMessageDirective } from "rl-common/components/form-table/form-table-message.directive";
import { ComponentKey } from "rl-common/components/mod-details-layout/mod-layout.consts";
import { IPanelGroup, PanelGroupType } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { Subscription } from "rxjs";
import { CheckboxInputComponent } from "../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";


export interface IPanelForm {
	title: FormControl<string>;
	panelType: FormControl<KeyValueOption>;
	displayOnSummary: FormControl<boolean>;
}

interface KeyValueOption {
	id: string;
	value: string;
}

@Component({
	selector: "rl-edit-panel-modal",
	templateUrl: "./edit-panel-modal.component.html",
	styleUrls: ["./edit-panel-modal.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, FormTableMessageDirective, TextInputComponent, ReactiveFormsModule, DropdownSingleComponent, CheckboxInputComponent, NgIf]
})
export class EditPanelModalComponent implements OnInit, OnDestroy, OnChanges {

	@Input()
	index: number;

	@Input()
	panels: IPanelGroup[];

	otherPanels: IPanelGroup[];
	panel: IPanelGroup;
	form: FormGroup<IPanelForm>;

	@Output()
	onComplete = new EventEmitter<IPanelGroup>();

	_subs: Subscription[] = [];

	get isValid() {
		return this.form.valid;
	}

	get isNewPanel() {
		return this.index === null || this.index === undefined;
	}

	panelTypeItems: KeyValueOption[] = [
		{ id: PanelGroupType.Configurable, value: "Configurable (Default)" },
		{ id: PanelGroupType.ContractComparison, value: "Contract Comparison" },
		{ id: PanelGroupType.Addresses, value: "Addresses" }
	];
	panelTypeOptions: DropdownOptions<KeyValueOption>;

	constructor(
		private readonly _formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.panelTypeOptions = {
			items: this.panelTypeItems,
			rowKeyFn: (type: KeyValueOption) => type.id,
			rowLabelFn: (type: KeyValueOption) => type.value
		};
		this.buildForm();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if ((changes.panels && this.panels) || (changes.index && this.index >= 0)) {
			this.buildForm();
		}
	}

	buildForm() {
		this.otherPanels = cloneDeep(this.panels ?? []);
		if (!this.isNewPanel && this.index >= 0 && this.index < this.otherPanels.length) {
			this.panel = first(this.otherPanels.splice(this.index, 1));
		}
		const panelType = this.panelTypeItems.find(x => x.id === this.panel?.type) ?? first(this.panelTypeItems)
		this.form = this._formBuilder.group({
			title: new FormControl(this.panel?.title ?? ``, { validators: Validators.required }),
			displayOnSummary: new FormControl(this.panel?.displayOnSummary),
			panelType: new FormControl(panelType),
		}, { validators: this.panelValidator });
	}

	close() {
		this.onComplete.emit(null);
	}

	done() {
		const panel = this.panel ?? { title: ``, type: PanelGroupType.Configurable, displayOnSummary: false, sections: [], childGroups: [] };
		panel.title = this.form.controls.title.value;
		panel.displayOnSummary = this.form.controls.displayOnSummary.value;
		panel.type = this.form.controls.panelType.value.id as PanelGroupType;
		switch (panel.type) {
			case PanelGroupType.ContractComparison:
				panel.sections = [{ key: ComponentKey.ContractComparison }];
				break;
			case PanelGroupType.Configurable:
				panel.sections = panel.sections.filter(x => x.key !== ComponentKey.ContractComparison);
		}
		this.onComplete.emit(panel);
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}

	private panelValidator: ValidatorFn = (form: FormGroup<IPanelForm>): ValidationErrors | null => {
		const panelTitle = form.controls.title.value.trim().toLocaleLowerCase();
		const otherTitles = this.otherPanels.map(x => x.title.trim().toLocaleLowerCase());
		if (otherTitles.includes(panelTitle)) {
			return { titleUniqueness: true };
		}
		return null;
	};

}