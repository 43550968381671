<div class="modal-header">
	<h2 class="modal-title">Select Fields for {{ templateName }}<span *ngIf="!!groupName"> > {{ groupName }}</span></h2>
	<button type="button" class="btn btn-close" aria-label="Cancel" (click)="cancel()" data-cy="closeTemplateFieldAdd">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="table-container scroll-vert">
		<table class="table rl-table table-hover">
			<thead>
				<tr>
					<th class="ps-3">ID</th>
					<th class="text-nowrap">Field Name</th>
					<th class="text-nowrap">Tag Label</th>
					<th class="text-nowrap">Data Type</th>
					<th>Visibility</th>
					<th>Required</th>
					<th>Multiple</th>
					<th class="text-nowrap">Conditional Field</th>
					<th class="text-nowrap">Include on Copy</th>
				</tr>
			</thead>
			<tbody>
				<tr class="no-items-message">
					<td colspan="9" class="border-0">
						<div class="d-flex align-items-center justify-content-center pt-3">
							<i class="far fa-th-list"></i>
							<h5 class="mb-0 ms-3">No items found.</h5>
						</div>
					</td>
				</tr>
				@for (char of templateChars; track char.characteristicID) {
				<tr cdkDrag cdkDragLockAxis="y" [cdkDragData]="char">
					<td class="text-nowrap">
						<input type="checkbox" class="form-check-input me-1" [value]="char.characteristicID"
							(change)="toggleField(char.characteristicID)" [checked]="isChecked(char.characteristicID)"
							data-cy="checkboxInputToggleSourceField" />
						{{char.characteristicID}}
					</td>
					<td>
						{{char.label}}
					</td>
					<td>
						{{char.tagLabel}}
					</td>
					<td>
						{{char.dataTypeID | charDataTypeName}}
					</td>
					<td>
						{{char.visibilityIndicator | visibilityIndicatorName}}
					</td>
					<td>
						{{char.requiredIndicator | requiredIndicatorName}}
					</td>
					<td>
						{{char.multipleIndicator | multipleIndicatorName}}
					</td>
					<td>
						{{char.triggerCharID ? "Yes" : "No"}}
					</td>
					<td>
						{{includeOnCopy(char.copyIndicator)}}
					</td>
				</tr>
				}
			</tbody>
		</table>
	</div>
	<div class="bg-light py-3 px-5">
		<h4>Can't find the field you're looking for?</h4>
		The source field must be added to the
		<a [routerLink]="['/config/templates', charTypeId, 'chars', templateId]" target="_blank">{{ templateName
			}}</a> template fields.
	</div>
	<div class="border-top pt-4 mt-4">
		<h4>Selected Fields</h4>
		<div class="mt-2 chips-container scroll-vert">
			<rl-chip *ngFor="let charId of fieldsToAdd" (onRemove)="toggleField(charId)">
				{{ getFieldById(charId) }}
			</rl-chip>
			<span *ngIf="!fieldsToAdd">No Fields selected.</span>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="submit" class="btn btn-light" (click)="cancel()" data-cy="cancelSelectionButton">Cancel
	</button>
	<button type="submit" class="btn btn-success ms-3" (click)="done()" [disabled]="fieldsToAdd.size === 0"
		data-cy="ApplySelectTemplateSourceField">Apply
	</button>
</div>