import { ComponentKey } from "./mod-layout.consts";

export enum PanelGroupType {
	/** The default panel group type which let's users configure their own layout */
	Configurable = "configurable",
	/** A dedicated panel for the contract comparison tool */
	ContractComparison = "contract-comparison",
	Addresses = "addresses"
}

/** This interface represents the actual section that renders an Angular component */
export interface IPanelSection<ComponentParams> {
	/** The key that we use to map to the correct Angular component */
	key: ComponentKey;
	/** The parameters for the component */
	params?: ComponentParams;
}

/** This is a grouping of sections and child groups (ie. sub panels or tabs) */
export interface IPanelGroup {
	/** The panel title */
	title: string;
	/** Determines what type of panel to render */
	type: PanelGroupType;
	/** The actual sections that will be rendered within a panel or tab (ie Royalty Rates -> [Field Section, Table: Percent of Revenue, Field Section]) */
	sections?: IPanelSection<unknown>[];
	/** child panel groupings (ie Royalties -> [Royalty Rates, Tiering, Exception Reporting]) */
	childGroups?: IPanelGroup[];
	/** Determines if the current panel group will show in the summary */
	displayOnSummary?: boolean;
}

export interface ILayoutGroup {
	/** the layout group title (the jump nav title) */
	title: string;
	/** child groupings of panels (ie Financial Terms -> [Royalties, Accounting]) */
	panels: IPanelGroup[];
}

export interface IModLayout {
	/** the name and grouping of panels */
	groups: ILayoutGroup[];
}

/** The layout for a module + template */
export interface IPersistedModLayout {
	id: number;
	/** the layout version (in case the JSON schema changes) */
	schemaVersion: number;
	/** the module's char type id */
	charTypeId: number;
	/** the module's template id */
	templateId: number;
	/** the layout (json) we've persisted*/
	layout: IModLayout;

	createdBy: number;
	createdOn: string;
	updatedBy: number;
	updatedOn: string;

	/** used for row versioning */
	rowVersion: number;
}




export function toComponentKeyName(key: ComponentKey): string {
	switch (key) {
		case ComponentKey.AccountingTables:
			return `Accounting Tables`;
		case ComponentKey.UdfLookup:
			return `UDF Lookup`;
		case ComponentKey.FieldSection:
			return `Field Section`;
		case ComponentKey.ContactPartyGroup:
			return `Party Section`;
		case ComponentKey.ComponentGrid:
			return `Component Grid`;
		case ComponentKey.AssociationGrid:
			return `Association Grid`;
		case ComponentKey.Media:
			return `Media`;
		case ComponentKey.JournalEntries:
			return `Journal Entry Grid`;
		case ComponentKey.ContractComparison:
			return `Intelligent Extract Comparison`;
		case ComponentKey.Schedule:
			return `Schedule`;
		case ComponentKey.MultiAddress:
			return `Addresses`;
		default:
			return `Unknown`;
	}
}