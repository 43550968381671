<div class="modal-header">
	<h2 class="modal-title">{{templateName}} Parties & Associations</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="modal-body p-0 scroll-vert tabset tabset--lg">
	<ul ngbNav #nav="ngbNav" class="nav nav-tabs mt-3">
		<li ngbNavItem>
			<a ngbNavLink data-cy="PartiesTab">Parties</a>
			<ng-template ngbNavContent>
				<rl-template-parties-tab></rl-template-parties-tab>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink data-cy="AssociationsTab">Associations</a>
			<ng-template ngbNavContent>
				<rl-template-associations-tab></rl-template-associations-tab>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-info ms-auto" (click)="close()" data-cy="DoneButton">
		Done
	</button>
</div>