import { transition, trigger, useAnimation } from "@angular/animations";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { FormTableControlDirective } from "rl-common/components/form-table/form-table-control.directive";
import { FormTableMessageDirective } from "rl-common/components/form-table/form-table-message.directive";
import { FormTableRowComponent } from "rl-common/components/form-table/form-table-row/form-table-row.component";
import { IPartyGroup } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { Subscription } from "rxjs";
import { take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { NumberInputComponent } from "../../../../../../common/components/number-input/number-input.component";
import { TextInputComponent } from "../../../../../../common/components/text/text-input/text-input.component";

interface SourcePartySectionEditForm {
	groupLabel: FormControl<string>;
	groupDescription: FormControl<string>;
	expandIndicator: FormControl<number>;
	expandGroupIndicator: FormControl<number>;
	visibilityIndicator: FormControl<KeyValueOption>;
	systemIndicator: FormControl<number>;
}

interface KeyValueOption {
	key: number;
	value: string;
}

@Component({
	selector: "rl-source-party-section-edit",
	templateUrl: "./source-party-section-edit.component.html",
	styleUrls: ["./source-party-section-edit.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, TextInputComponent, CheckboxInputComponent, DropdownSingleComponent, NumberInputComponent, FormTableRowComponent, FormTableControlDirective, FormTableMessageDirective]
})
export class SourcePartySectionEditComponent implements OnInit, OnDestroy {

	@Input()
	sourcePartySection: IPartyGroup = null;

	@Output()
	onComplete = new EventEmitter<boolean>();

	formLoaded = false;
	title = "";
	form: FormGroup<SourcePartySectionEditForm>;
	isSaving = false;
	_subs: Subscription[] = [];
	isSuperAdmin: boolean;

	get isCreate() {
		return !this.sourcePartySection;
	}

	get isEdit() {
		return !!this.sourcePartySection;
	}

	visibilityList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};

	constructor(
		private _entityConfigService: EntityConfigService,
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _sessionService: SessionService) { }

	ngOnInit(): void {
		this.setupVisibilityList();
		const sub2 = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;

				if (this.isCreate) {
					this.title = "Create New Source Party";
					this.setupCreateSourcePartySection();
				} else {
					this.title = "Edit Source Party - <strong>" + this.sourcePartySection.groupLabel + "</strong>";
					this.setupEditSourcePartySection();
				}
			})
		).subscribe();
		this._subs.push(sub2);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	setupVisibilityList() {
		this.visibilityList.items.push({ key: 0, value: "Hidden" });
		this.visibilityList.items.push({ key: 1, value: "Internal Only" });
		this.visibilityList.items.push({ key: 2, value: "Both Internal & External" });
	}

	setupCreateSourcePartySection() {
		const _fb = this._fb;
		this.form = _fb.group<SourcePartySectionEditForm>({
			groupLabel: _fb.control(null, Validators.required),
			groupDescription: _fb.control(null, {}),
			expandIndicator: _fb.control(1, {}),
			expandGroupIndicator: _fb.control(null, {}),
			visibilityIndicator: _fb.control({ key: 2, value: null }, {}),
			systemIndicator: _fb.control({ value: 0, disabled: !this.isSuperAdmin })
		});

		this.formLoaded = true;
	}

	setupEditSourcePartySection() {
		const _fb = this._fb;
		this.form = _fb.group<SourcePartySectionEditForm>({
			groupLabel: _fb.control(this.sourcePartySection.groupLabel, Validators.required),
			groupDescription: _fb.control(this.sourcePartySection.groupDescription, {}),
			expandIndicator: _fb.control(this.sourcePartySection.expandIndicator, {}),
			expandGroupIndicator: _fb.control(this.sourcePartySection.expandGroupIndicator, {}),
			visibilityIndicator: _fb.control({ key: this.sourcePartySection.visibilityIndicator, value: null }, {}),
			systemIndicator: _fb.control({ value: this.sourcePartySection.systemIndicator, disabled: !this.isSuperAdmin })
		});

		this.formLoaded = true;
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			let success = true;
			let errorMessage = "";
			try {
				const sourcePartySection: Partial<IPartyGroup> = {
					groupLabel: this.form.controls.groupLabel.value,
					groupDescription: this.form.controls.groupDescription.value,
					expandIndicator: +this.form.controls.expandIndicator.value,
					expandGroupIndicator: +this.form.controls.expandGroupIndicator.value,
					systemIndicator: this.form.controls.systemIndicator.value,
					visibilityIndicator: this.form.controls.visibilityIndicator.value.key
				};

				if (this.isCreate) {
					sourcePartySection.sequenceNumber = 0;
					await this._entityConfigService.createSourcePartySection(sourcePartySection as IPartyGroup).toPromise();
				}
				if (this.isEdit) {
					sourcePartySection.groupID = this.sourcePartySection.groupID;
					sourcePartySection.sequenceNumber = this.sourcePartySection.sequenceNumber;
					await this._entityConfigService.updateSourcePartySection(sourcePartySection as IPartyGroup).toPromise();
				}
			} catch (e) {
				success = false;
				errorMessage = e?.error?.message;
			} finally {
				this.isSaving = false;
			}
			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Source Party Section saved.");
			} else {
				this._growlerService.error().growl(errorMessage);
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}

}
