import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { ISourceField } from "rl-common/services/entity-config/entity-config.models";
import { TemplateAssociationsTabComponent } from "../template-associations-tab/template-associations-tab.component";
import { TemplatePartiesTabComponent } from "../template-parties-tab/template-parties-tab.component";

@Component({
	selector: "rl-template-details-modal",
	templateUrl: "./template-details-modal.component.html",
	styleUrls: ["./template-details-modal.component.scss"],
	imports: [NgbNav, NgbNavOutlet, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, TemplatePartiesTabComponent, TemplateAssociationsTabComponent]
})
export class TemplateDetailsModalComponent implements OnInit {

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	templateName: string;

	@Output()
	onComplete = new EventEmitter<ISourceField[]>();

	constructor(
		private activeModal: NgbActiveModal) {

	}

	ngOnInit(): void {
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}

	submit() {

	}

	cancel() {
		this.activeModal.close(false);
	}

}
