import { NgClass } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { every } from "lodash";
import { ConstUtils } from "rl-common/consts";
import { ICharacteristicTypeAssociation, ITemplateAssociation, ITemplateAssociationsPanel } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { Subscription } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { TrimWhitespacePipe } from "../../../../common/pipes/trim-whitespace.pipe";
import { TemplateAssociationsPanelComponent } from "../template-associations-panel/template-associations-panel.component";
import { EntitiesAssociationsPanelComponent } from "../template-associations/entities-associations-panel/entities-associations-panel.component";
import { TemplateLandingService } from "../templates-landing/templates-landing.service";

@Component({
    selector: "rl-template-associations-content",
    templateUrl: "./template-associations-content.component.html",
    styleUrls: ["./template-associations-content.component.scss"],
    imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, LoaderComponent, TemplateAssociationsPanelComponent, EntitiesAssociationsPanelComponent, TrimWhitespacePipe]
})
export class TemplateAssociationsContentComponent implements OnInit, OnDestroy, AfterViewInit {
	templateAssociations: ITemplateAssociation[];
	allEntityAssociations: ITemplateAssociation;
	moduleAssociations: ICharacteristicTypeAssociation[];
	componentAssociations: ICharacteristicTypeAssociation[];
	moduleTemplates: ITemplateAssociation[];
	componentTemplates: ITemplateAssociation[];
	charTypeId: number;
	templateId: number;
	templateName: string;
	isModule: boolean;
	isLoaded: boolean = false;
	panels: ITemplateAssociationsPanel[] = [];
	_subs: Subscription[] = [];

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	@ViewChildren("item")
	accordionItemsQueryList: QueryList<NgbAccordionItem>;

	accordionItems: NgbAccordionItem[] = [];

	get isAllCollapsed() {
		return every(this.accordionItems, item => item.collapsed);
	}

	constructor(private readonly _entityConfigService: EntityConfigService,
		private readonly _templateService: TemplateLandingService) { }

	ngOnInit() {
		const sub = this._templateService.templateMetaData$.pipe(
			take(1),
			tap(result => {
				ConstUtils.isModuleLevelCharType(result.charTypeID) ? this.isModule = true : this.isModule = false;
				this.charTypeId = result.charTypeID;
				this.templateId = result.templateID;
				this.templateName = result.template.templateName;
				this.setPanelNames();
			})
		).subscribe();

		const sub2 = this._entityConfigService.getRelationships(this.charTypeId, this.templateId).pipe(
			map(result => {
				const charTypeTemplates: ITemplateAssociation[] = [];
				const moduleCharTypes: ICharacteristicTypeAssociation[] = [];
				const componentCharTypes: ICharacteristicTypeAssociation[] = [];
				const moduleTemps: ITemplateAssociation[] = [];
				const componentTemps: ITemplateAssociation[] = [];

				Object.values(result.templateAssociation).forEach(ta => {
					if (+ta.childCharTypeID === this.charTypeId) {
						charTypeTemplates.push(ta);
					}

					if (ConstUtils.isModuleLevelCharType(+ta.childCharTypeID) && +ta.childCharTypeID !== this.charTypeId) {
						moduleTemps.push(ta);
					}

					if (ConstUtils.isComponentLevelCharType(+ta.childCharTypeID) && +ta.childCharTypeID !== this.charTypeId) {
						componentTemps.push(ta);
					}
				});

				Object.values(result.charTypeAssociation).forEach(cta => {
					if (ConstUtils.isModuleLevelCharType(+cta.charTypeID) && +cta.charTypeID !== this.charTypeId) {
						moduleCharTypes.push(cta);
					}

					if (ConstUtils.isComponentLevelCharType(+cta.charTypeID) && +cta.charTypeID !== this.charTypeId) {
						componentCharTypes.push(cta);
					}
				});

				this.templateAssociations = [...charTypeTemplates];
				this.moduleAssociations = [...moduleCharTypes];
				this.componentAssociations = [...componentCharTypes];
				this.allEntityAssociations = result.templateAssociation;
				this.moduleTemplates = moduleTemps;
				this.componentTemplates = componentTemps;
				this.isLoaded = true;
			})
		).subscribe();

		this._subs.push(sub, sub2);
	}

	ngAfterViewInit(): void {
		this.accordionItems = this.accordionItemsQueryList.toArray();
	}

	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}

	loadAssociations() {
		return this._entityConfigService.getRelationships(this.charTypeId, this.templateId);
	}

	setPanelNames() {
		const moduleString = this.isModule ? "other Module" : "Module";
		const componentString = !this.isModule ? "other Component" : "Component";
		this.panels = [
			{ panelID: 0, panelName: `Associations to ${this.templateName} Templates` },
			{ panelID: 1, panelName: `Associations to ${moduleString} Templates` },
			{ panelID: 2, panelName: `Associations to ${componentString} Templates` }
		]
	}

}