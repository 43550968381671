import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplateAssociationCollection, ITemplateAssociation, ITemplateChanges, SyncAndAvailsOptions } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { Subscription } from "rxjs";
import { take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../common/components/dropdown/dropdown-single/dropdown-single.component";

@Component({
	selector: "rl-template-associations-panel",
	templateUrl: "./template-associations-panel.component.html",
	styleUrls: ["./template-associations-panel.component.scss"],
	imports: [NgIf, NgFor, CheckboxInputComponent, ReactiveFormsModule, FormsModule, DropdownSingleComponent]
})
export class TemplateAssociationsPanelComponent implements OnInit, OnDestroy {
	templateAssociationCollection: ICharacteristicTemplateAssociationCollection;
	_subs: Subscription[] = [];

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	templateName: string;

	@Input()
	templateAssociations: ITemplateAssociation[] = [];
	relRecType: [number, string][];

	syncAndAvailsOptionsList: DropdownOptions<[number, string]> = {
		items: [
			[SyncAndAvailsOptions.NoSyncNoAvails, "No Rights or Deal sync. No Avails relevancy."],
			[SyncAndAvailsOptions.YesSyncYesAvails, "Rights and Deal sync. Avails Relevant."],
			[SyncAndAvailsOptions.NoSyncYesAvails, "No Rights or Deal sync. Avails Relevant."]
		],
		rowKeyFn: (type: [number, string]) => type[0],
		rowLabelFn: (type: [number, string]) => type[1]
	};

	selectedRelRecTypeIdAndNode: string = null;

	constructor(private readonly _entityConfigService: EntityConfigService,
		private readonly _growlerService: GrowlerService) { }

	ngOnInit(): void {
		this.relRecType = this.templateAssociations.map(x => [
			x.relRecTypeID,
			x.relRecTypeID > 0 ? this.syncAndAvailsOptionsList.items.find(y => y[0] == x.relRecTypeID)[1] : ""
		]);
	}

	toggleSelected(templateAssociation: ITemplateAssociation, i: number) {
		templateAssociation.relRecTypeID = templateAssociation.selected ? 1 : -1;
		this.relRecType[i] = this.syncAndAvailsOptionsList.items.find(x => x[0] == templateAssociation.relRecTypeID);

		let sub;
		const model: ITemplateChanges = {
			charTypeID: this.charTypeId,
			templateID: this.templateId,
			childCharTypeID: templateAssociation.childCharTypeID,
			childTemplateID: templateAssociation.childTemplateID,
			relRecTypeID: SyncAndAvailsOptions.NoSyncNoAvails,
			selected: templateAssociation.selected
		};

		if (model.charTypeID === CharTypeId.Property && model.childCharTypeID === CharTypeId.Property) {
			sub = this._entityConfigService.updateTemplateRelationship(model).pipe(
				take(1),
				tap({
					next: () => this._growlerService.success().growl("Template association updated."),
					error: (error) => this._growlerService.error().growl(error?.message)
				})
			).subscribe();
		} else {
			sub = this._entityConfigService.toggleTemplateRelationship(model).pipe(
				take(1),
				tap({
					next: () => this._growlerService.success().growl("Template association updated."),
					error: (error) => this._growlerService.error().growl(error?.message)
				})
			).subscribe();
		}

		this._subs.push(sub);
	}

	// Only available for catalog-to-catalog rels
	updateSyncAndAvails(template: ITemplateAssociation, i: number) {
		const model: ITemplateChanges = {
			charTypeID: this.charTypeId,
			templateID: this.templateId,
			childCharTypeID: template.childCharTypeID,
			childTemplateID: template.childTemplateID,
			relRecTypeID: this.relRecType[i][0],
			selected: template.selected
		};
		this.templateAssociations[i].relRecTypeID = model.relRecTypeID;

		if (model.charTypeID === CharTypeId.Property && model.childCharTypeID === CharTypeId.Property) {
			const sub = this._entityConfigService.updateTemplateRelationship(model).pipe(
				take(1),
				tap({
					next: () => this._growlerService.success().growl("Template association updated."),
					error: (error) => this._growlerService.error().growl(error?.message)
				})
			).subscribe();

			this._subs.push(sub);
		}
	}

	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}
}