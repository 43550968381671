<div class="modal-body" *ngIf="modalLoaded; else isLoading" [@fadeIn]>
	<div class="modal-header">
		<h2 class="modal-title">{{ isCreate ? 'Add Party to' : 'Edit Party on' }} {{charTypeName$ | async }} >
			{{templateName$ | async}} > {{groupName}}</h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeEditParty">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)">
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Source Party'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.sourceParty"
							(onValueChange)="updateTemplatePartyName($event)" [options]="allParties"
							[showEmptyOption]="true" [dataLabel]="'selectDropdownSourceParty'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Template Party Name'" *ngIf="form.controls.templatePartyName">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.templatePartyName"
							[dataLabel]="'textInputTemplatePartyName'"></rl-text-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Party Section'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.partyGroup"
							(onValueChange)="updateGroupName($event)" [options]="groups" [showEmptyOption]="true"
							[dataLabel]="'selectDropdownPartySection'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Required'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.required"
							[dataLabel]="'checkboxInputRequiredYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Editable'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.editable"
							[dataLabel]="'checkboxInputEditableYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Deletable'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.deletable"
							[dataLabel]="'checkboxInputDeletableYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Can be Multiple'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.multiple"
							[dataLabel]="'checkboxInputMultipleYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Valid Contact Templates'" *ngIf="form.controls.validContactTemplates">
					<ng-template rlFormTableControl>
						<label class="ps-1px py-2">
							<input type="checkbox" [formControl]="form.controls.toggleValidContactTemplates"
								class="form-check-input me-2" (change)="toggleContactTemplates($event)"
								data-cy="checkboxInputTemplatesCheckAll" />
							Select All
						</label>
						<div class="scroll-vert border-top py-2 padding-left-1px">
							<div *ngFor="let template of contactTemplates; let i = index"
								class="char-data-element-display">
								<label class="py-1">
									<input type="checkbox"
										[formControl]="form.get('validContactTemplates')?.controls[template.templateName]"
										[value]="template.templateId" class="form-check-input me-2"
										[attr.data-cy]="'checkboxInputRequired' + template.templateName | trimWhitespace" />
									{{ template.templateName }}
								</label>
							</div>
						</div>
					</ng-template>
				</rl-form-table-row>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelTemplateParty">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveTemplateParty"><i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save</button>
		</div>
	</form>
</div>

<ng-template #primaryPartyPrompt let-primaryPartyPrompt="prompt">
	<div>
		<p>
			Setting a Party Primary Indicator to 'Yes' will set any other Party Association's primary indicator to
			'No'.<br />A reindex will also occur which could take several minutes. <b>Are you sure you want to
				continue</b>?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="primaryPartyPrompt.onConfirm.next(false)" data-cy="deleteCancel">
			No
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="primaryPartyPrompt.onConfirm.next(true)"
			data-cy="deleteConfirm">
			Yes
		</button>
	</div>
</ng-template>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>