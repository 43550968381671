import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ConfigModalService } from "config/services/config-modal.service";
import { isEqual } from "lodash";
import { IFieldSectionParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { Subscription } from "rxjs";
import { filter, finalize, switchMap, tap } from "rxjs/operators";
import { CharDataTypeNamePipe } from "../../../../../common/pipes/char-data-type-name.pipe";
import { VisibilityIndicatorPipe } from "../../../../../common/pipes/visibility-indicator-name.pipe";
import { MultipleIndicatorNamePipe } from "../../../../pipes/multiple-indicator-name.pipe";
import { RequiredIndicatorNamePipe } from "../../../../pipes/required-indicator-name.pipe";
import { VisibilityIndicatorNamePipe } from "../../../../pipes/visibility-indicator-name.pipe";
import { TemplateLandingService } from "../../templates-landing/templates-landing.service";


@Component({
	selector: "rl-field-section-char-picker",
	templateUrl: "./field-section-char-picker.component.html",
	styleUrls: ["./field-section-char-picker.component.scss"],
	imports: [CdkDropList, CdkDrag, CdkDragHandle, CharDataTypeNamePipe, VisibilityIndicatorPipe, VisibilityIndicatorNamePipe, RequiredIndicatorNamePipe, MultipleIndicatorNamePipe, RouterLink]
})
export class FieldSectionCharPickerComponent implements OnInit, OnDestroy, OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	characteristicIds: number[] = [];

	@Input()
	groupName: string = '';

	@Output()
	onChange = new EventEmitter<number[]>();

	params: IFieldSectionParams;

	groupChars: ICharacteristicMetaData[] = [];
	oneConfigRefreshing = false;

	_subs: Subscription[] = [];

	constructor(
		private readonly _templateService: TemplateLandingService,
		private readonly _modalService: ConfigModalService,
		private readonly _injector: Injector,
		private readonly _growlerService: GrowlerService,
		private readonly _oneConfig: OneConfigService,
		private readonly _tokenStorageService: TokenStorageService
	) { }

	ngOnInit() {
		this.updateGroupChars(this.characteristicIds);
	}

	labelFn(cmd: ICharacteristicMetaData) {
		return cmd.label;
	}

	keyFn(cmd: ICharacteristicMetaData): string | number {
		return cmd.characteristicID;
	}

	groupLabelFn(cmd: ICharacteristicMetaData) {
		const group = (this.templateMetaData?.groups ?? []).find(x => x.groupID === cmd.groupID);
		return group?.groupLabel;
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.characteristicIds && !isEqual(changes.characteristicIds.currentValue, changes.characteristicIds.previousValue) || changes.templateMetaData) {
			this.updateGroupChars(this.characteristicIds ?? []);
		}
	}

	private updateGroupChars(characteristicIds: number[]) {
		if (!this.templateMetaData) {
			return;
		}

		const groupChars = characteristicIds
			.map(charId => this.templateMetaData.characteristicMetaDatas.find(x => x.characteristicID === charId))
			.filter(x => !!x);

		this.groupChars = groupChars;
	}

	emitChange() {
		const characteristicIds = this.groupChars.map(cmd => cmd.characteristicID);
		this.characteristicIds = characteristicIds;
		this.onChange.emit(characteristicIds);
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousIndex === event.currentIndex) {
			return;
		}
		moveItemInArray(this.groupChars, event.previousIndex, event.currentIndex);
		this.emitChange();
	}

	addFields() {
		const sub = this._modalService.selectFieldsForSection(this.templateMetaData, this.characteristicIds, this.groupName, this._injector).subscribe((selected) => {
			if (selected) {
				this.characteristicIds.push(...Array.from(selected));
				this.updateGroupChars(this.characteristicIds);
			}
			this.emitChange();
		});
		this._subs.push(sub);
	}

	editField(char: ICharacteristicMetaData) {
		if (this.oneConfigRefreshing) {
			this._growlerService.info().growl("Cannot edit field while configuration is refreshing...");
			return;
		}
		const group = this.templateMetaData.groups.find(x => x.groupID === char.groupID);
		const sub = this._modalService.addTemplateField(group, char, this.templateMetaData.templateID, this._injector).pipe(
			filter(event => !!event),
			tap(() => this.oneConfigRefreshing = true),
			switchMap(() => this._oneConfig.refresh(this._tokenStorageService.jwt)),
			switchMap(() => this._templateService.refresh$()),
			finalize(() => {
				this.oneConfigRefreshing = false;
			})
		).subscribe();
		this._subs.push(sub);
		this.emitChange();
	}

	removeField(char: ICharacteristicMetaData) {
		this.groupChars = this.groupChars.filter(x => x.characteristicID !== char.characteristicID);
		this.emitChange();
	}

	includeOnCopy(copyIndicator: number) {
		return copyIndicator === 1;
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}
