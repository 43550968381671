<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="row">
				<div class="col-8">
					<div class="rounded d-flex align-items-center p-3 alert alert-danger" *ngIf="systemSourceField">
						This is a system Source Field and some fields cannot be modified.
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-8">
					<div class="char-data__group">
						<rl-form-table-row [labelText]="'Field Name'" [required]="true"
							[class.has-error]="form.controls.sourceFieldName.touched && form.invalid && form.controls.sourceFieldName.value === ''">
							<ng-template rlFormTableControl>
								<rl-text-input [formControl]="form.controls.sourceFieldName"
									[dataLabel]="'textInputFieldName'"></rl-text-input>
							</ng-template>
							<ng-template rlFormTableMessage
								*ngIf="form.controls.sourceFieldName.touched && form.invalid && form.controls.sourceFieldName.value === ''">
								Field Name is required.
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Tag Label'" [required]="true"
							[class.has-error]="(form.controls.sourceFieldName.touched || form.controls.mergeTag.touched) && form.invalid && form.controls.mergeTag.value === ''">
							<ng-template rlFormTableControl>
								<rl-text-input [formControl]="form.controls.mergeTag"
									[dataLabel]="'textInputTagLabel'"></rl-text-input>
							</ng-template>
							<ng-template rlFormTableMessage
								*ngIf="(form.controls.sourceFieldName.touched || form.controls.mergeTag.touched) && form.invalid && form.controls.mergeTag.value === ''">
								Tag Label is required.
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Tag Label Description'">
							<ng-template rlFormTableControl>
								<rl-text-input [formControl]="form.controls.description"
									[dataLabel]="'textInputDescription'"></rl-text-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Extractable'" *rlFeatureEnabled="'aiFeatures'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.extractable"
												   [dataLabel]="'checkboxInputExtractableYes'"
												   class="d-block py-1"></rl-checkbox-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Document Extraction Hint'"
							*rlFeatureEnabled="'aiFeatures'">
							<ng-template rlFormTableControl>
								<rl-text-input [formControl]="form.controls.hint"
									[dataLabel]="'textInputHint'"></rl-text-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Data Type'">
							<ng-template rlFormTableControl>
								<rl-dropdown-single [(formControl)]="form.controls.dataType" [options]="dataTypeList"
									[showEmptyOption]="true" [dataLabel]="'selectDropdownDataType'">
								</rl-dropdown-single>
							</ng-template>
						</rl-form-table-row>

						@if(showMultiIndicator) {
						<rl-form-table-row [labelText]="'Can be Multiple'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.multipleIndicator"
									[dataLabel]="'checkboxInputMultipleYes'" class="d-block py-1"></rl-checkbox-input>
							</ng-template>
						</rl-form-table-row>
						}

						<rl-form-table-row [labelText]="'Max Length'">
							<ng-template rlFormTableControl>
								<rl-number-input [formControl]="form.controls.length"
									[dataLabel]="'numberInputMaxLength'"></rl-number-input>
							</ng-template>
						</rl-form-table-row>

						<!-- Only show the PickList Source field if Data Type is set to AlphaNum, Money or Checkbox (Classic parity) -->
						<rl-form-table-row [labelText]="'Picklist Source'"
							*ngIf="picklistDataTypeIds.includes(getNumericValue(form.controls.dataType.value?.key))">
							<ng-template rlFormTableControl>
								<rl-dropdown-single [(formControl)]="form.controls.charValueSet"
									[options]="pickListSourceList" [showEmptyOption]="true"
									[dataLabel]="'selectDropdownPicklistSource'">
								</rl-dropdown-single>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'System Indicator'">
							<ng-template rlFormTableControl>
								<rl-number-input [formControl]="form.controls.systemIndicator"
									[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Report Indicator'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.reportIndicator"
									[dataLabel]="'checkboxInputReportIndicatorYes'"
									class="d-block py-1"></rl-checkbox-input>
							</ng-template>
						</rl-form-table-row>
					</div>
				</div>
				<div class="col-4 px-4">
					<div *ngIf="sourceField?.sourceFieldID && sourceField?.usedIn?.length > 0"
						class="alert alert-success">
						<h5 class="pe-4">The {{sourceField.charTypeID | charTypeName}} Source Field
							<strong>{{sourceField.sourceFieldName}}</strong> is used in
							{{sourceField.usedIn.length}}
							templates:
						</h5>
						<ul class="mb-0 ps-3">
							<li *ngFor="let usedIn of sourceField.usedIn" class="py-1 ps-3">
								{{usedIn.templateLbl}} &bull; {{usedIn.templateSourceFieldGroupLbl}} &bull;
								{{sourceField.sourceFieldName}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton"><i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>