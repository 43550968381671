import { Component } from "@angular/core";
import { TemplatePartiesContentComponent } from "../template-parties-content/template-parties-content.component";

@Component({
	selector: "rl-template-parties-tab",
	templateUrl: "./template-parties-tab.component.html",
	styleUrls: ["./template-parties-tab.component.scss"],
	imports: [TemplatePartiesContentComponent]
})
export class TemplatePartiesTabComponent {


	constructor() { }


}
