import { transition, trigger, useAnimation } from "@angular/animations";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { FormTableControlDirective } from "rl-common/components/form-table/form-table-control.directive";
import { FormTableMessageDirective } from "rl-common/components/form-table/form-table-message.directive";
import { FormTableRowComponent } from "rl-common/components/form-table/form-table-row/form-table-row.component";
import { ISourceFieldSection } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../../common/components/checkbox-input/checkbox-input.component";
import { NumberInputComponent } from "../../../../../../common/components/number-input/number-input.component";
import { TextInputComponent } from "../../../../../../common/components/text/text-input/text-input.component";

interface SourceFieldSectionEditForm {
	charTypeId: FormControl<number>;
	charGroupId: FormControl<number>;
	charGroupLabel: FormControl<string>;
	systemIndicator: FormControl<number>;
	expGroupIndicator: FormControl<number>;
}

@Component({
    selector: "rl-source-field-section-edit",
    templateUrl: "./source-field-section-edit.component.html",
    styleUrls: ["./source-field-section-edit.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [NgIf, ReactiveFormsModule, FormsModule, TextInputComponent, NumberInputComponent, CheckboxInputComponent, FormTableRowComponent, FormTableControlDirective, FormTableMessageDirective]
})
export class SourceFieldSectionEditComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId = null;

	@Input()
	sourceFieldSection: ISourceFieldSection = null;

	@Output()
	onComplete = new EventEmitter<boolean>();

	formLoaded = false;
	title = "";
	form: FormGroup<SourceFieldSectionEditForm>;
	isSaving = false;
	_subs: Subscription[] = [];

	isSuperAdmin: boolean;

	get isCreate() {
		return !this.sourceFieldSection;
	}

	get isEdit() {
		return !!this.sourceFieldSection;
	}

	constructor(
		private _entityConfigService: EntityConfigService,
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _sessionService: SessionService) { }

	ngOnInit(): void {

		const sub2 = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;
			})
		).subscribe();
		this._subs.push(sub2);

		if (this.sourceFieldSection === null) {
			// create mode
			this.title = "Create New " + CharTypeIdUtil.toReportLabel(this.charTypeId) + " Source Field Section";
			this.setupCreateSourceField();
		} else {
			this.title = "Edit " + CharTypeIdUtil.toReportLabel(this.charTypeId) + " Source Field Section - <strong>" +
				this.sourceFieldSection.charGroupLabel + "</strong>";
			this.setupEditSourceField();
		}
	}

	setupCreateSourceField() {
		const _fb = this._fb;
		this.form = _fb.group<SourceFieldSectionEditForm>({
			charTypeId: _fb.control(this.charTypeId, Validators.required),
			charGroupId: _fb.control(null), // Generated on create
			charGroupLabel: _fb.control(null, Validators.required),
			systemIndicator: _fb.control({ value: 0, disabled: !this.isSuperAdmin}),
			expGroupIndicator: _fb.control(0, {})
		});

		this.formLoaded = true;
	}

	setupEditSourceField() {
		const _fb = this._fb;
		this.form = _fb.group<SourceFieldSectionEditForm>({
			charTypeId: _fb.control(this.charTypeId, Validators.required),
			charGroupId: _fb.control(this.sourceFieldSection.charGroupId, Validators.required),
			charGroupLabel: _fb.control(this.sourceFieldSection.charGroupLabel, Validators.required),
			systemIndicator: _fb.control({ value: this.sourceFieldSection.systemIndicator, disabled: !this.isSuperAdmin}),
			expGroupIndicator: _fb.control(this.sourceFieldSection.expGroupIndicator, {})
		});

		this.formLoaded = true;
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			let success = true;
			let errorMessage = "";
			try {
				const sourceFieldSection: Partial<ISourceFieldSection> = {
					charGroupId: this.form.controls.charGroupId.value,
					charGroupLabel: this.form.controls.charGroupLabel.value,
					systemIndicator: this.form.controls.systemIndicator.value,
					sequenceNumber: this.sourceFieldSection ? this.sourceFieldSection.sequenceNumber : 0, // Not needed on create
					expGroupIndicator: +this.form.controls.expGroupIndicator.value
				};

				if (this.isCreate) {
					await this._entityConfigService.createSourceFieldSection(this.charTypeId, sourceFieldSection as ISourceFieldSection).toPromise();
				}
				if (this.isEdit) {
					await this._entityConfigService.updateSourceFieldSection(this.charTypeId, sourceFieldSection as ISourceFieldSection).toPromise();
				}
			} catch(e) {
				success = false;
				errorMessage = e?.error?.message;
			} finally {
				this.isSaving = false;
			}
			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Source Field Section saved.");
			} else {
				this._growlerService.error().growl(errorMessage);
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
