
import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { ICharType, ITemplateAssociation, ITemplateAssociationsPanel, ITemplateChanges, PanelKey, SyncAndAvailsOptions } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../common/components/checkbox-input/checkbox-input.component";
import { CharTypeNamePipe } from "../../../../../common/pipes/char-type-name.pipe";
import { TrimWhitespacePipe } from "../../../../../common/pipes/trim-whitespace.pipe";
@Component({
	selector: "rl-entities-associations-panel",
	templateUrl: "./entities-associations-panel.component.html",
	styleUrls: ["./entities-associations-panel.component.scss"],
	imports: [NgIf, NgFor, CheckboxInputComponent, ReactiveFormsModule, FormsModule, CharTypeNamePipe, TrimWhitespacePipe]
})
export class EntitiesAssociationsPanelComponent implements OnInit, OnDestroy {
	templateAssocDict: _.Dictionary<ITemplateAssociation[]> = {};
	associationCharTypes: ICharType[] = [];
	associationHeaders: string[] = [];
	associationIds: number[] = [];
	associationTemplates = [];
	_subs: Subscription[] = [];
	requiredAssociation = "Required Association";
	showHeader = false;
	numRows: number = 0;
	selectedRequiredTemplates: number[] = [];

	@Input()
	isModule: boolean;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	panel: ITemplateAssociationsPanel;

	@Input()
	allEntityAssociations: ITemplateAssociation[];

	constructor(private readonly _entityConfigService: EntityConfigService,
		private readonly _growlerService: GrowlerService) { }

	ngOnInit() {
		this.mapTemplates();
		this.getPanelData();
		const associationChars = this.getRequiredAssociations();
		this.showHeader = (this.charTypeId === CharTypeId.Right || this.charTypeId === CharTypeId.Usage || this.charTypeId == CharTypeId.Amount) && (associationChars.indexOf(CharTypeId.Property) > -1 || associationChars.indexOf(CharTypeId.User) > -1 || associationChars.indexOf(CharTypeId.Project) > -1)
	}

	private getRequiredAssociations() {
		const associationChars = this.associationCharTypes.map(x => x.charTypeID);
		const reqSub = this._entityConfigService.getRequiredAssociations(this.charTypeId, this.templateId, associationChars).subscribe((results) => {
			this.selectedRequiredTemplates = results;
		});
		this._subs.push(reqSub);
		return associationChars;
	}

	private mapTemplates() {
		this.templateAssocDict = this.allEntityAssociations.reduce((acc, tempAssoc) => {
			const targetTemplates: ITemplateAssociation[] = this.allEntityAssociations.filter(ea => ea.childCharTypeID === tempAssoc.childCharTypeID);
			if (tempAssoc.childCharTypeID !== this.charTypeId) {
				acc[tempAssoc.childCharTypeID] = targetTemplates;
			}
			return acc;
		}, {});
	}

	isSelected(groupCharTypeId: number) {
		return this.selectedRequiredTemplates.indexOf(groupCharTypeId) > -1;
	}

	getPanelData() {
		Object.keys(this.templateAssocDict)
			.filter(ct => +ct !== CharTypeId.Mock)
			.forEach(ct => {
				let templates: ITemplateAssociation[] = [];
				if (this.panel.panelID === PanelKey.Modules) {
					if (ConstUtils.isModuleLevelCharType(+ct)) {
						templates = Object.entries(this.templateAssocDict).find((kvp) => +kvp[0] === +ct)[1];
						this.associationCharTypes.push({ charTypeID: +ct, charTypeName: CharTypeIdUtil.toReportLabel(+ct), charTypeTemplates: templates });
					}
				} else {
					if (ConstUtils.isComponentLevelCharType(+ct)) {
						templates = Object.entries(this.templateAssocDict).find((kvp) => +kvp[0] === +ct)[1];
						this.associationCharTypes.push({ charTypeID: +ct, charTypeName: CharTypeIdUtil.toReportLabel(+ct), charTypeTemplates: templates });
					}
				}

				this.associationHeaders = [...Object.values(this.associationCharTypes).map(x => x.charTypeName)];
				this.associationIds = [...Object.values(this.associationCharTypes).map(x => x.charTypeID)];
				this.associationTemplates = Object.values(this.associationCharTypes).map(x => x.charTypeTemplates);
			});

		this.getMaxNumRows();
	}

	toggleSelected(event: ITemplateAssociation) {
		event.selected = !event.selected;

		const model: ITemplateChanges = {
			charTypeID: this.charTypeId,
			templateID: this.templateId,
			childCharTypeID: event.childCharTypeID,
			childTemplateID: event.childTemplateID,
			relRecTypeID: SyncAndAvailsOptions.NoSyncNoAvails,
			selected: event.selected
		}

		const sub = this._entityConfigService.toggleTemplateRelationship(model).pipe(
			take(1),
			tap({
				next: () => this._growlerService.success().growl("Template association updated."),
				error: (error) => this._growlerService.error().growl(error?.message)
			})
		).subscribe();

		this._subs.push(sub);
	}

	shouldShowRequiredCheckbox(groupCharTypeId: number) {
		if ((this.charTypeId === CharTypeId.Right || this.charTypeId === CharTypeId.Usage || this.charTypeId === CharTypeId.Amount) && (groupCharTypeId === CharTypeId.Property ||
			groupCharTypeId === CharTypeId.User || groupCharTypeId === CharTypeId.Project)) {
			return true;
		} else {
			return false;
		}
	}

	toggleRequired(charTypeId: number) {
		const associationChars = this.associationCharTypes.map(x => x.charTypeID);
		if (this.isSelected(charTypeId)) {
			const createSub = this._entityConfigService.deleteRecordAssociationConfigurationRequiredEntry(this.charTypeId, this.templateId, charTypeId).pipe(
				switchMap(() => this._entityConfigService.getRequiredAssociations(this.charTypeId, this.templateId, associationChars)),
				tap((results) => {
					this.selectedRequiredTemplates = results;
				})
			).subscribe();
			this._subs.push(createSub);
		} else {
			const deleteSub = this._entityConfigService.createRecordAssociationConfigurationRequiredEntry(this.charTypeId, this.templateId, charTypeId).pipe(
				switchMap(() => this._entityConfigService.getRequiredAssociations(this.charTypeId, this.templateId, associationChars)),
				tap((results) => {
					this.selectedRequiredTemplates = results;
				})
			).subscribe();
			this._subs.push(deleteSub);
		}
	}

	getMaxNumRows() {
		this.associationTemplates.forEach(grp => {
			if (grp.length > this.numRows) {
				this.numRows = grp.length;
			}
		});
	}

	rowCounter(i: number) {
		return new Array(i);
	}

	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}
}