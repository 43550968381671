<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Section Name'" [required]="true"
					[class.has-error]="form.controls.groupLabel.touched && form.invalid && form.controls.groupLabel.value === ''">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.groupLabel"
							[dataLabel]="'textInputSectionName'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.groupLabel.touched && form.invalid && form.controls.groupLabel.value === ''">
						Section Name is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Description'">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.groupDescription"
							[dataLabel]="'textInputDescription'"></rl-text-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Expand Group on Load'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.expandIndicator"
							[dataLabel]="'checkboxInputExpandGroupYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Expand Contacts on Load'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.expandGroupIndicator"
							[dataLabel]="'checkboxInputExpandContactsYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Visibility'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.visibilityIndicator"
							[options]="visibilityList" [showEmptyOption]="true"
							[dataLabel]="'selectDropdownVisibility'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'System Indicator'"
					[class.has-error]="form.controls.systemIndicator.value < 0 && form.invalid">
					<ng-template rlFormTableControl>
						<rl-number-input [formControl]="form.controls.systemIndicator" [min]="0"
							[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
					</ng-template>
					<ng-template rlFormTableMessage *ngIf="form.controls.systemIndicator.value < 0 && form.invalid">
						Negative values are invalid.
					</ng-template>
				</rl-form-table-row>
			</div>

		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton"><i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>