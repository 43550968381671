<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Action Name'" [required]="true"
					[class.has-error]="form.hasError('actionNameRequired') && form.controls.actionName.touched">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.actionName"
							[dataLabel]="'inputFieldActionName'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.hasError('actionNameRequired') && form.controls.actionName.touched">
						Action Name is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Description'">
					<ng-template rlFormTableControl>
						<rl-text-area [formControl]="form.controls.actionDescription" [styles]="{maxLength: 10000}"
							[dataLabel]="'textareaDescription'"></rl-text-area>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Initial Action'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.initialAction"
							[dataLabel]="'checkboxInputInitialActionYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Next Status'" [required]="true"
					[class.has-error]="form.hasError('nextStatusRequired') && form.controls.nextStatus.touched">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.nextStatus" [options]="allSteps"
							[showEmptyOption]="true" [dataLabel]="'selectDropdownNextStatus'">
						</rl-dropdown-single>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.hasError('nextStatusRequired') && form.controls.nextStatus.touched">
						Next Status is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Document Options'" [required]="true"
					[class.has-error]="form.hasError('docIndicatorRequired') && form.controls.docIndicator.touched">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.docIndicator" [options]="documentOptionsList"
							[showEmptyOption]="true" [dataLabel]="'selectDropdownDocumentOptions'">
						</rl-dropdown-single>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.hasError('docIndicatorRequired') && form.controls.docIndicator.touched">
						Document Options is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="charTypeId === 7" [labelText]="'Update Currency Conversion'">
					<ng-template rlFormTableControl>
						<div class="d-flex align-items-center">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.updateCurrencyConversion"
								[dataLabel]="'checkboxInputUpdateCurrencyConversionYes'"
								class="d-block py-1"></rl-checkbox-input>
							<span class="h-auto btn-icon btn-info btn-sm cursor-pointer text-medium" role="button"
								data-pendo="tooltip-update-currency-conversion"><i aria-label="Tooltip"
									class="far fa-question-circle"></i></span>
						</div>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="charTypeId === 1 || charTypeId === 4"
					[labelText]="'Action allowed from Status with Blocker Conflicts'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.allowActionWithBlockers"
							[dataLabel]="'checkboxInputAllowBlockersYes'" class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Associated Roles'">
					<ng-template rlFormTableControl>
						<rl-workflow-associated-roles [form]="form" [allRoles]="allRoles"
							[isCreate]="isCreate"></rl-workflow-associated-roles>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Chained Actions'">
					<ng-template rlFormTableControl>
						<rl-workflow-chained-action [form]="form" [processes]="processes" [actions]="actions">
						</rl-workflow-chained-action>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Notifications'">
					<ng-template rlFormTableControl>
						<rl-workflow-notification [form]="form" [notifications]="notifications" [allRoles]="allRoles"
							[allParties]="allParties" [isCreate]="isCreate" class="d-block w-100">
						</rl-workflow-notification>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Extensions'">
					<ng-template rlFormTableControl>
						<div class="d-flex align-items-center p-2" *ngIf="wfExtensionResult.paths.length === 0">None
						</div>
						<table class="table rl-table" *ngIf="wfExtensionResult.paths.length > 0">
							<thead>
								<tr>
									<th>Name</th>
									<th>Type</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let path of wfExtensionResult.paths">
									<td>{{ path.path }}</td>
									<td>{{ path.pathTypeID === 1 ? 'Stored Proc' : 'Service' }}</td>
								</tr>
							</tbody>
						</table>
					</ng-template>
				</rl-form-table-row>
			</div>
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Allow Action During Validations'">
					<ng-template rlFormTableControl>
						<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.allowActionDuringValidations"
							[dataLabel]="'checkboxInputAllowActionDuringValidations'"
							class="d-block py-1"></rl-checkbox-input>
					</ng-template>
				</rl-form-table-row>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton"><i *ngIf="isSaving" class="far fa-spinner fa-spin"></i>Save</button>
		</div>
	</div>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>