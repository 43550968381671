<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Party Name'" [required]="true"
					[class.has-error]="form.controls.partyName.touched && form.invalid && form.controls.partyName.value === ''">
					<ng-template rlFormTableControl>
						<rl-text-input [formControl]="form.controls.partyName"
							[dataLabel]="'textInputPartyName'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.partyName.touched && form.invalid && form.controls.partyName.value === ''">
						Party Name is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Party Description'" [required]="true"
					[class.has-error]="form.controls.partyDescription.touched && form.invalid && form.controls.partyDescription.value === ''">
					<ng-template rlFormTableControl>
						<rl-text-area [formControl]="form.controls.partyDescription" [styles]="{maxLength: 10000}"
							[dataLabel]="'textareaInputDescription'"></rl-text-area>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.partyDescription.touched && form.invalid && form.controls.partyDescription.value === ''">
						Party Description is required.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'System Indicator'">
					<ng-template rlFormTableControl>
						<rl-number-input [formControl]="form.controls.systemIndicatorId"
							[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
					</ng-template>
				</rl-form-table-row>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>