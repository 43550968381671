<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeEditTemplate">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<div *ngIf="_charTypeId === 3 && _templateId">
					<div class="alert alert-warning my-3" style="display: inline-block;">
						Making changes here and saving could result in an automated avails refresh for your instance.
						Please proceed with discretion.</div>
				</div>

				<rl-form-table-row *ngIf="form.controls.templateId" [labelText]="'Template ID'">
					<ng-template rlFormTableControl>
						<rl-text-input [(formControl)]="form.controls.templateId"
							[displayAsText]="form.controls.templateId" [dataLabel]="'templateIdInput'"></rl-text-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Template Name'" [required]="true"
					[class.has-error]="form.controls.templateName.invalid && form.controls.templateName.touched">
					<ng-template rlFormTableControl>
						<rl-text-input [(formControl)]="form.controls.templateName"
							[dataLabel]="'templateNameInput'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.templateName.errors?.required && form.controls.templateName.touched">
						Template Name is required.
					</ng-template>
					<ng-template rlFormTableMessage *ngIf="form.controls.templateName.errors?.templateNameDuplicate">
						Duplicate Template Name is not allowed.
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Template Group'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.templateGroup" [options]="groups"
							[showEmptyOption]="true" [dataLabel]="'selectTemplateGroup'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Workflow Process'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.workflowProcess" [options]="processes"
							[showEmptyOption]="false" [dataLabel]="'selectWorkflowProcess'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="_charTypeId === 3" [labelText]="'Deal Scoped'">
					<ng-template rlFormTableControl>
						<rl-dropdown-single [(formControl)]="form.controls.dealScoped" [options]="dealScopedOptionsList"
							[showEmptyOption]="false" [dataLabel]="'selectDealScoped'">
						</rl-dropdown-single>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="form.controls.systemIndicator" [labelText]="'System Indicator'">
					<ng-template rlFormTableControl>
						<rl-number-input [formControl]="form.controls.systemIndicator"
							[dataLabel]="'systemIndicatorInput'"></rl-number-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row [labelText]="'Calc Sequence'">
					<ng-template rlFormTableControl>
						<rl-number-input [formControl]="form.controls.calcSequence"
							[dataLabel]="'calcSequenceInput'"></rl-number-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="form.controls.charTypeId.value === 3" [labelText]="'Color'">
					<ng-template rlFormTableControl>
						<rl-color-picker-input [formControl]="form.controls.visualization"></rl-color-picker-input>
					</ng-template>
				</rl-form-table-row>

				<rl-form-table-row *ngIf="isDeal" labelText="Amortization Model">
					<ng-template rlFormTableControl>
						@if(amortOptions.items.length > 0) {
							<rl-dropdown-multiple [formControl]="form.controls.selectedAmortModels" [options]="amortOptions" [showEmptyOption]="true">
							</rl-dropdown-multiple>
						} @else {
							<label>
								<input type="checkbox" class="form-check-input" [ngModel]="true" [disabled]="true" [ngModelOptions]="{standalone: true}" />
								Straight Line
							</label>
						}
					</ng-template>
				</rl-form-table-row>

			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelTemplateEdit">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveTemplateEdit">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>