import { transition, trigger, useAnimation } from "@angular/animations";
import { AsyncPipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ISourceField } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Observable, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { LoaderComponent } from "../../../../../common/components/panel/loader/loader.component";
import { TemplateLandingService } from "../../templates-landing/templates-landing.service";
import { CreateTemplateFieldWizardComponent } from "./create-template-field-wizard/create-template-field-wizard.component";
import { ICreateTemplateFormData } from "./create-template-field-wizard/template-field-policy-editor/template-field-policy-editor.models";
import { SelectTemplateSourceFieldComponent } from "./select-template-source-field/select-template-source-field.component";
import { ITemplateFieldAddComponent, TemplateFieldAddModalAdapter } from "./template-field-add.adapter";

@Component({
	selector: "rl-template-field-add",
	templateUrl: "./template-field-add.component.html",
	styleUrls: ["./template-field-add.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, SelectTemplateSourceFieldComponent, CreateTemplateFieldWizardComponent, NgbNavOutlet, LoaderComponent, AsyncPipe]
})
export class TemplateFieldAddComponent implements OnInit, OnDestroy, ITemplateFieldAddComponent {
	_subs: Subscription[] = [];
	formGroup: FormGroup<ICreateTemplateFormData>;
	isSaving = false;
	charTypeId$: Observable<number>;
	templateId$: Observable<number>;
	charTypeName$: Observable<string>;
	templateName$: Observable<string>;
	charTypeId: number;
	charTypeName: string;
	templateName: string;
	charValueSourceId: string;
	charValueSetId: string;
	conditionalTriggerFieldId: FormControl<number>;
	formLoaded = false;
	title = "";
	activeTabId = 1;
	unUsed = -1;
	notAssigned: ISourceField[] = [];
	groups: ITemplateCharacteristicGroup[] = [];

	@Input()
	group: ITemplateCharacteristicGroup;

	@Input()
	templateFieldModel: ICharacteristicMetaData;

	@Input()
	charMetaData: ICharacteristicMetaData[];

	@Input()
	templateId: number;

	@Output()
	onComplete = new EventEmitter<boolean>();

	modalLoaded = false;
	sourceFieldsLoaded = false;

	get isCreate() {
		return !this.templateFieldModel;
	}

	get isEdit() {
		return !!this.templateFieldModel;
	}

	constructor(
		private _templateService: TemplateLandingService,
		private _entityConfigService: EntityConfigService
	) { }

	ngOnInit(): void {
		if (!this.templateId) {
			this._templateService.charTypeTemplateIds$.pipe(
				map(([_, templateId]) => templateId),
				tap(templateId => {
					if (!this.templateId) {
						this.templateId = templateId;
					}
				})
			).subscribe();
		} else if (this.templateFieldModel && !this.charTypeId) {
			this.charTypeId = this.templateFieldModel.charTypeID;
			this.charTypeName = this.charTypeId ? CharTypeIdUtil.toReportLabel(this.charTypeId) : null;
		} else if (!this.templateFieldModel && !this.charTypeId) {
			this._templateService.charTypeTemplateIds$.pipe(
				map(([charTypeId, _]) => charTypeId),
				tap(charTypeId => {
					if (!this.charTypeId) {
						this.charTypeId = charTypeId;
						this.charTypeName = this.charTypeId ? CharTypeIdUtil.toReportLabel(this.charTypeId) : null;
					}
				})
			).subscribe();
		}

		// TODO: we should merge these requests into a single endpoint to reduce round trips to the API
		const sub = this._entityConfigService.getTemplateMetaData(this.charTypeId, this.templateId).pipe(
			tap(results => {
				this.groups = results.groups;
				this.templateName = results.template.templateName;
				this.charMetaData = results.characteristicMetaDatas ?? [];
				this.setTitle();
			}),
			switchMap(() => this._entityConfigService.getSourceFields(this.charTypeId, this.unUsed)),
			tap((result) => {
				const sourceFields = result.sort((a, b) => (a.sourceFieldName.localeCompare(b.sourceFieldName)));
				this.notAssigned = sourceFields.filter(x => !this.charMetaData.find(y => y.characteristicID === x.sourceFieldID));
				this.sourceFieldsLoaded = true;
			})
		).subscribe();

		this.modalLoaded = true;
		this._subs.push(sub);
	}

	setTitle() {
		const groupLabel = this.group.groupLabel;
		if (this.isCreate) {
			this.title = `Add Fields to ${this.charTypeName} > ${this.templateName} > <strong>${groupLabel}</strong>`;
		} else {
			this.title = `Edit Template Field - ${this.charTypeName} > ${this.templateName} > ${groupLabel} > <strong>${this.templateFieldModel.label}</strong>`;
		}
	}

	onCharValueSourceIdChanged(charValueSourceId: string) {
		this.charValueSourceId = charValueSourceId;
	}

	onCharValueSetIdChanged(charValueSetId: string) {
		this.charValueSetId = charValueSetId;
	}

	async save(fields: Set<number>) {
		await this._entityConfigService.associateCharacteristicListToTemplate(this.charTypeId, this.templateId, this.group.groupID, Array.from(fields)).toPromise();
		this.onComplete.next(true);
	}

	saveWizard() {
		const sub = this._entityConfigService.getTemplateMetaData(this.charTypeId, this.templateId).pipe(
			tap(metaData => {
				this._templateService.templateCharacteristicMetaDatas$.next(metaData.characteristicMetaDatas);
				this._templateService.characteristicMetaDatas = [...metaData.characteristicMetaDatas];
				this.onComplete.next(true);
			})
		).subscribe();

		this._subs.push(sub);
	}

	cancel() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}

export const TEMPLATEFIELDADD_MODAL_PROVIDER: Provider = {
	provide: TemplateFieldAddModalAdapter,
	useFactory: () => new TemplateFieldAddModalAdapter(TemplateFieldAddComponent)
};

