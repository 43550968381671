import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { EntityConfigUtil } from "config/utils/entity-config.util";
import { every } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { FormTableControlDirective } from "rl-common/components/form-table/form-table-control.directive";
import { FormTableCustomControlDirective } from "rl-common/components/form-table/form-table-custom-control.directive";
import { FormTableMessageDirective } from "rl-common/components/form-table/form-table-message.directive";
import { FormTableRowComponent } from "rl-common/components/form-table/form-table-row/form-table-row.component";
import { ConstUtils } from "rl-common/consts";
import { IAssocRolesLabels, IWfRole, IWfRoleStepAssociation, IWfStep, IWfStepSaveRequest } from "rl-common/services/company/company.models";
import { IWFActionConfig, IWFAssocAction, RevisionStatus } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { RevisionStatusUtil } from "rl-common/utils/revision-status.util";
import { Subscription } from "rxjs";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { NumberInputComponent } from "../../../../../../../common/components/number-input/number-input.component";
import { TextAreaComponent } from "../../../../../../../common/components/text/text-area/text-area.component";
import { TextInputComponent } from "../../../../../../../common/components/text/text-input/text-input.component";
import { TrimWhitespacePipe } from "../../../../../../../common/pipes/trim-whitespace.pipe";
import { WorkflowProcessesService } from "../../../workflow-processes.service";

interface IWorkflowStatusForm {
	statusId: FormControl<number>;
	statusName: FormControl<string>;
	statusDescription: FormControl<string>;
	activeIndicator: FormControl<number>;
	deleteIndicator: FormControl<number>;
	lockIndicator: FormControl<number>;
	systemIndicator: FormControl<number>;
	validActions: FormArray<FormControl<number>>;
	associatedRoles: FormArray<FormControl<IAssocRolesLabels>>;
	revisionStatus: FormControl<KeyValueOption>;
}

interface KeyValueOption {
	key: number;
	value: string;
}

@Component({
	selector: "rl-workflow-status-edit",
	styleUrls: ["./workflow-status-edit.component.scss"],
	templateUrl: "./workflow-status-edit.component.html",
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, TextInputComponent, ReactiveFormsModule, TextAreaComponent, DropdownSingleComponent, CheckboxInputComponent, NumberInputComponent, FormsModule, NgFor, TrimWhitespacePipe, FormTableRowComponent, FormTableControlDirective, FormTableMessageDirective, FormTableCustomControlDirective]
})
export class WorkflowStatusEditComponent implements OnInit, OnDestroy {
	_subs: Subscription[] = [];
	charTypeId: number;
	processId: number;
	setStatusName: string;
	title: string;
	form: FormGroup<IWorkflowStatusForm>;
	actions: IWFActionConfig[] = [];
	roles: IWfRole[] = [];
	selectedValues: unknown[] = [];
	actionAssocs: IWFAssocAction[] = [];
	roleAssocs: IWfRoleStepAssociation[] = [];
	checkedActionIds: number[] = [];
	selectedAssocRoles: IAssocRolesLabels[];
	preselectedAssocRoles: IWfRole[] = [];
	formLoaded = false;
	isSaving = false;
	isSuperAdmin: boolean;
	unifiedWorkflow = FeatureKeys.UnifiedWorkflow;
	isUnifiedWorkflowActive = false;
	revisionStatuses: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};

	@Input()
	statusId: number;

	@Input()
	wfStatusModel: IWfStep;

	@Output()
	onComplete = new EventEmitter<boolean>();

	get isCreate() {
		return this.statusId === 0;
	}

	get associatedRolesIsEmpty() {
		return this.form.controls.associatedRoles && this.form.controls.associatedRoles.length === 0;
	}

	get associatedRolesFormControls() {
		return this.form.controls.associatedRoles && this.form.controls.associatedRoles.controls;
	}

	get allValidActionsSelected() {
		const allValidActionIds = this.actions && this.actions.map(a => a.actionID) || [];
		return every(allValidActionIds, id => this.checkedActionIds.includes(id));
	}

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly _workflowProcessesService: WorkflowProcessesService,
		private readonly _sessionService: SessionService,
		private readonly _featureService: FeatureService
	) { }

	ngOnInit() {
		const sub = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;
			}),
			switchMap(() => this._workflowProcessesService.fetchCharTypeProcessIds$()),
			tap(result => {
				this.charTypeId = result[0];
				this.processId = result[1];

				if (!this.isCreate) {
					this.statusId = this.wfStatusModel.stepID;
					this.setStatusName = this.wfStatusModel.stepName;
				}

				this.setTitle();
			}),
			switchMap(() => this._featureService.isEnabled$(FeatureKeys.UnifiedWorkflow)),
			take(1),
			tap(result => {
				this.isUnifiedWorkflowActive = result && ConstUtils.isModuleLevelCharType(this.charTypeId);
				if (this.isUnifiedWorkflowActive) {
					this.getRevisionStatuses();
				}
			}),
			switchMap(() => this._entityConfigService.getWFStepsActionsRolesAndAssocs(this.charTypeId, this.statusId, this.processId)),
			filter(results => !!results),
			take(1),
			tap(results => {
				this.actions = results.wfActionConfig;
				this.roles = results.wfRoles;
				this.actionAssocs = results.wfActionAndStepRoleAssocsUx2Response.wfAssocAction;
				this.roleAssocs = results.wfActionAndStepRoleAssocsUx2Response.wfStepRoleAssoc;

				if (!this.isCreate) {
					this.wfStatusModel.selectedActionIDs = this.setValidActions(this.actionAssocs.map(assoc => assoc.actionID));
					this.wfStatusModel.stepRoleLabels = this.buildPreselectedAssocRolesMap();
					this.buildForm(this.wfStatusModel);
				} else {
					this.buildForm();
					this.selectAllValidActions(true);
				}
				this.formLoaded = true;
			})
		).subscribe();

		this._subs.push(sub);
	}

	setTitle() {
		this.title = this.isCreate ? "Create New Workflow Status" : `Edit Workflow Status - ${this.setStatusName}`;
	}

	buildForm(model: IWfStep = null) {
		if (model) {
			if (this.isUnifiedWorkflowActive && isNaN(model.revisionStatusIndicator)) {
				model.revisionStatusIndicator = this.getInitialRevisionStatus();
			}
			this.form = this._fb.group<IWorkflowStatusForm>({
				statusId: this._fb.control(model.stepID),
				statusName: this._fb.control(model.stepName, [Validators.required]),
				statusDescription: this._fb.control(model.stepDescription),
				activeIndicator: this._fb.control(model.activeIndicator),
				deleteIndicator: this._fb.control(model.deleteIndicator),
				lockIndicator: this._fb.control(model.lockIndicator),
				systemIndicator: this._fb.control({ value: model.systemIndicator, disabled: !this.isSuperAdmin }),
				validActions: this._fb.array(model.selectedActionIDs),
				associatedRoles: this._fb.array(model.stepRoleLabels),
				revisionStatus: this._fb.control({ key: model.revisionStatusIndicator, value: null })
			});
		} else {
			const initialRevisionStatus = this.getInitialRevisionStatus();
			this.form = this._fb.group<IWorkflowStatusForm>({
				statusId: this._fb.control(-1),
				statusName: this._fb.control("", [Validators.required]),
				statusDescription: this._fb.control(""),
				activeIndicator: this._fb.control(0),
				deleteIndicator: this._fb.control(0),
				lockIndicator: this._fb.control(0),
				systemIndicator: this._fb.control({ value: 0, disabled: !this.isSuperAdmin }),
				validActions: new FormArray<FormControl<number>>([]),
				associatedRoles: new FormArray<FormControl<IAssocRolesLabels>>([]),
				revisionStatus: this._fb.control({ key: initialRevisionStatus, value: null })
			});
		}
	}

	getInitialRevisionStatus(): number {
		if (this.isUnifiedWorkflowActive) {
			return RevisionStatus.InSetup;
		}
		return null;
	}

	setValidActions(actionIds: number[]) {
		const ids = this.actions.map(a => a.actionID);
		this.checkedActionIds = ids.filter(id => actionIds.includes(id));
		return this.checkedActionIds;
	}

	isActionIdChecked(actionId: number) {
		return this.checkedActionIds.includes(actionId);
	}

	onValidActionChange(id: number, isChecked: boolean) {
		const validActionsFormArray = this.form.controls.validActions as FormArray<FormControl<number>>;

		if (isChecked) {
			validActionsFormArray.push(new FormControl<number>(id));
			if (!this.checkedActionIds.includes(id)) {
				this.checkedActionIds.push(id);
			}
		} else {
			const index = validActionsFormArray.controls.findIndex(x => x.value === id);
			validActionsFormArray.removeAt(index);
			const checkedIndex = this.checkedActionIds.indexOf(id);
			this.checkedActionIds.splice(checkedIndex, 1);
		}

		this.form.controls.validActions.updateValueAndValidity();
	}

	selectAllValidActions(isChecked: boolean) {
		const validActionsFormArray = this.form.controls.validActions as FormArray<FormControl<number>>;

		if (isChecked) {
			validActionsFormArray.clear();
			const idArray = this.actions.map(a => a.actionID);
			idArray.forEach(id => {
				validActionsFormArray.push(new FormControl<number>(id));
			});
			this.checkedActionIds = idArray;
		} else {
			this.form.controls.validActions.clear();
			this.checkedActionIds = [];
		}
	}

	buildPreselectedAssocRolesMap() {
		this.preselectedAssocRoles = this.roles.filter(r => this.roleAssocs.some(ra => ra.roleID === r.roleID));
		const outputArray = [] as IAssocRolesLabels[];
		if (this.preselectedAssocRoles.length) {
			const mappedPreselected = this.preselectedAssocRoles.reduce((acc, curr) => {
				acc[curr.roleID] = curr;
				return acc;
			}, {});
			const combined = this.roleAssocs.map(r => Object.assign(r, mappedPreselected[r.roleID]));
			combined.forEach(r => {
				const idString = `${r.roleID} ${r.roleName}`;
				outputArray.push({
					id: idString,
					label: r.stepName
				});
			});
		}

		return outputArray;
	}

	omitRoleOption(id: string) {
		return this.form.controls.associatedRoles.value.map(r => r.id).includes(id);
	}

	addNewRow() {
		this.form.controls.associatedRoles.push(new FormControl<IAssocRolesLabels>({ id: "", label: "" }));
	}

	removeRow(index: number) {
		this.form.controls.associatedRoles.removeAt(index);
	}

	clearRows() {
		this.form.controls.associatedRoles.clear();
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			try {
				const assocRoles = this.form.controls.associatedRoles.value;
				const assocRolesDict = Object.assign({}, ...assocRoles.map(x => ({ [x.id.substring(0, x.id.indexOf(" "))]: x.label })));

				const model: IWfStepSaveRequest = {
					stepID: this.form.controls.statusId.value,
					processID: this.processId,
					divisionID: null,
					charTypeID: this.charTypeId,
					stepName: this.form.controls.statusName.value,
					stepDescription: this.form.controls.statusDescription.value,
					lockIndicator: this.form.controls.lockIndicator.value,
					activeIndicator: this.form.controls.activeIndicator.value,
					deleteIndicator: this.form.controls.deleteIndicator.value,
					sequenceNumber: this.wfStatusModel ? this.wfStatusModel.sequenceNumber : 0,
					systemIndicator: this.form.controls.systemIndicator.value,
					createdBy: this.wfStatusModel ? this.wfStatusModel.createdBy : null,
					actionName: "",
					selectedActionIDs: this.form.controls.validActions.value,
					stepRoleLabels: assocRolesDict,
					revisionStatusIndicator: this.form.controls.revisionStatus.value.key
				};

				await this._entityConfigService.saveWFStep(model).toPromise();
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;

				if (success) {
					this.onComplete.next(true);
					this._growlerService.success().growl("Your changes were saved.");
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	getRevisionStatuses() {
		EntityConfigUtil.getRevisionStatus().forEach(option => {
			if (option >= 1) {
				this.revisionStatuses.items.push({ key: option, value: RevisionStatusUtil.toDisplayName(option) })
			}
		})
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}
}
