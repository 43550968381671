<rl-form-table-row>
	<ng-template rlFormTableLabel>Group Name</ng-template>
	<ng-template rlFormTableControl>
		<rl-text-input class="mb-4" [(ngModel)]="groupName" (ngModelChange)="updateParams()"
			[dataLabel]="'GroupNameInput'"></rl-text-input>
	</ng-template>
</rl-form-table-row>

<rl-form-table-row>
	<ng-template rlFormTableLabel>Expand Section on Load</ng-template>
	<ng-template rlFormTableControl>
		<rl-checkbox-input [label]="'Yes'" [(ngModel)]="expandOnLoad" (ngModelChange)="updateParams()"
			[dataLabel]="'ExpandOnLoad'" class="d-block py-1"></rl-checkbox-input>
	</ng-template>
</rl-form-table-row>

<rl-form-table-row>
	<ng-template rlFormTableLabel>Column Display</ng-template>
	<ng-template rlFormTableControl>
		<rl-radio-input class="d-block py-1" [label]="'One column'" [name]="groupName + '-numColumns-one'"
			[(ngModel)]="numColumns" [value]="1" [dataLabel]="'numColumnsOne'"
			(ngModelChange)="updateParams()"></rl-radio-input>
		<rl-radio-input class="d-block py-1" [label]="'Two columns'" [name]="groupName + '-numColumns-two'"
			[(ngModel)]="numColumns" [value]="2" [dataLabel]="'numColumnsTwo'"
			(ngModelChange)="updateParams()"></rl-radio-input>
	</ng-template>
</rl-form-table-row>

<rl-field-section-char-picker [templateMetaData]="templateMetaData" [characteristicIds]="characteristicIds"
	[groupName]="groupName" (onChange)="updateElements($event)">
</rl-field-section-char-picker>