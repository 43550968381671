import { Component } from "@angular/core";
import { TemplateAssociationsContentComponent } from "../template-associations-content/template-associations-content.component";

@Component({
    selector: "rl-template-associations-tab",
    templateUrl: "./template-associations-tab.component.html",
    styleUrls: ["./template-associations-tab.component.scss"],
    imports: [TemplateAssociationsContentComponent]
})
export class TemplateAssociationsTabComponent {

	constructor() { }

}