<div class="modal-header">
	<h2 class="modal-title">
		@if(section) {
		Edit {{ elementName }} Element
		} @else {
		New {{ elementName }} Element
		}
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="closeButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-build-panel-element [templateMetaData]="templateMetaData" [section]="section" [componentKey]="componentKey"
		(onChange)="workingElement = $event"></rl-build-panel-element>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light ms-auto" (click)="close()" data-cy="CancelButton">Cancel</button>
	<button (click)="done()" class="btn btn-success" data-cy="ApplyButton">
		Apply
	</button>
</div>